.input__order__container{
    // margin-right: 15px;
    // height: 82px;
    margin-top:20px;
    input{
        width: 200px;
        height: 40px;
        border-radius: 5px;
        border: solid 1px #e2e2e2;
        background-color: #f9f9f9;
        font-family: Rubik;
        font-size: 16px;
        text-indent: 5px;
        cursor: pointer;
    }

    input:focus {
        outline: none;
        background-color: #f9f9f9;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

    .input__order__title{
        margin-bottom: 8px;
    }
  
}