.selectInputContainer__table {
    position: relative;
    top: -10px;
    // border: 1px solid red;

    .makeStyles-formControl-35 {
        margin: 0px !important;
        min-width: auto !important;
    }
    .makeStyles-selectEmpty-36{
        margin-top: 0px !important;
    }

    .makeStyles-formControl-22{
        margin: 0px;
    }
}