.sidebar__main{
    width: 100%;
    height: auto;
    border: 1px solid #CACBCC;
    //border-left: 1px solid #CACBCC;
    //border-right: 1px solid #CACBCC;
     border-radius: 10px;
    //  position: relative;
    //  top: 52px;
}
.sidebar__main__heading{
   font-size: 16px;
   font-weight: 800;
   line-height: 18px;
   color: #6C7389;
   display: flex;
   align-items: center;
}
// .sidebar__main__heading > span{
//     display: flex;
//     justify-items: center;
// }
.sidebar__main__header{
    padding: 10px;
    height: 52px;
    width: 100%;
    color: #3C93D6;
    background-color: #C5E1FA;
    border-radius: 9px;
    // position: absolute;
    // right: 0px;
    // top: 0px;
}
.sidebar__main__item{
    padding: 10px;
    height: 71px;
    width: 100%;
    // border-left: 1px solid #CACBCC;
    // border-right: 1px solid #CACBCC;
    border-bottom: 1px dashed #CACBCC;
    display: flex;
    align-items: center;
}
.sidebar__main__text{
   font-size: 16px;
   font-weight: 400;
   line-height: 18px;
   color: #455768;
}