.drop__down__main__container {
  margin-bottom: 0px !important;

  // display: flex;
  // justify-content: center;

  .drop__down__section {
    position: absolute;
    cursor: pointer;
  }
}
.sales__project__manager__container {
  // .validate__message {
  //   // margin-top: 53px;
  // }
}

.drop__down__section__icon {
  float: left;
  bottom: 16px;
  right: 24px;
  position: relative;
  color: black;
  .fa-sort-down {
    font-size: 16px;
    left: 11px;
    position: relative;
  }
  .fa-sort-up {
    left: 11px;
    position: relative;
    top: 6px !important;
    font-size: 16px;
  }
}

#drop__down__section--off {
  transform: scaleY(0);
  transition: transform 0.2s;
  transform-origin: bottom;
  // display: none;
}

.drop__down__header {
  font-family: Rubik;
  font-size: 12px;
  padding-right: 2px;

  .selected__name {
    width: 57px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
}

#drop__down__section--active {
  transform: scaleY(1);
  transition: transform 0.2s;
  transform-origin: bottom;
  border-radius: 5px;
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);
  padding: 10px 3px;
  bottom: 13px;
  overflow: auto;
  max-height: 140px;
}

#drop__down__section--active::-webkit-scrollbar {
  height: 15px;
  width: 8px;
}

#drop__down__section--active::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(177, 177, 177);
  border-radius: 10px;
  margin-right: 189px;
}

#drop__down__section--active::-webkit-scrollbar-thumb {
  background: #0091ff;
  border-radius: 10px;
}

.drop__down__list__items {
  z-index: 3;
  position: absolute;
  // position: relative;
  background-color: white;
  bottom: 13px;
  box-shadow: 0 0 0.3em #acacac;

  .drop__down__item {
    height: 25px;
    width: 100%;
    font-family: Rubik;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 14px;
    white-space: nowrap;
    border-radius: 5px;
    align-items: center;
    display: flex;

    span {
      padding-right: 5px;
      padding-left: 5px;
      margin-top: 8px;
    }
  }
}

/* width */
.drop__down__list__items::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.drop__down__list__items::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.drop__down__list__items::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.drop__down__list__items::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.drop__down__item:hover {
  background-color: #f1f1f1;
}
