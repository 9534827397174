.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 1px !important;
}

.okIncorrect{
	width: 70%;
	margin: auto;
}

td .commentsClass {
    padding: 15px;
    width: 100%;
}

.commentsClass div:first-child {
	padding: 3px 10px;
}

.redColor .okIncorrect{
    border: 1px solid red;
}
.redColor .okIncorrect input[type=text]{
    color: red;
}