.popUp__background {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background-color: rgba($color: #4A4A4A, $alpha: 0.7);

    @media screen and (max-width: 450px) {
        opacity: 1;
    }
}


.desktopTab__footer {
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 100%;
}
.desktopTab__footer__btnContainer {
    display: flex;
   justify-content: left;
    margin: 0px 10px;
    padding: 10px 0px;
    // width: 100%;
    background-color: #ffffff;
}

