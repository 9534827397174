

@import '../abstracts/mixins';
@import '../abstracts/variables';


.select__box__icon {
  float: left;
  margin-left: 4px;
  
  .fa-sort-down {
    font-size: 17px;
    transform: translateY(-3px);
  }
  .fa-sort-up{
    position: relative;
    top: 4px!important;
  }
}





#selected__box--off {
  transform: scaleY(0);
  transition: transform 0.2s;
  transform-origin: top;

  // li {
  //   display: none; 
  // }
}

.header__select {
  width: 167px;
  height: 32px;
  font-family: Rubik;
  font-size: 14px;
  margin-top: 1px;
  padding-top: 13px;
  border-bottom: 2px solid #dfdfdf ;
  cursor: pointer;
  margin-top:4px;
}

#selected__box--active {
  z-index: 20;
  position: relative;
  border-radius: 5px;
  padding: 11px 3px;
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  transform: scaleY(1);
  transition: transform 0.2s;
  transform-origin: top;
  transform: scaleY(1);
  transition: transform 0.2s;
  transform-origin: top;
  margin-bottom: 31px;
  & > ul {
    background-color: #ffffff;
   


  }
  li {
    width: 100%;
    position: relative;
    height: 30px;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    align-items: center;
   


    &:hover {
      background-color: #f1f1f1;
    }
  }
}

.selectbox__list {
  height: 200px;
  overflow-y: auto;
  
  

  .selectbox__option {
    height: 37px;
    background-color: #f9f9f9;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Rubik;
    padding-top: 8px;

    span {
      margin-right: 5px;
    }
  }
}

// /* width */
// .selectbox__list::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// .selectbox__list::-webkit-scrollbar-track {
//   background: #f1f1f1; 
// }
 
// /* Handle */
// .selectbox__list::-webkit-scrollbar-thumb {
//   background: #888; 
// }

// /* Handle on hover */
// .selectbox__list::-webkit-scrollbar-thumb:hover {
//   background: #555; 
// }

// .selectbox__option:hover {
//   background-color: #dbdbdb;
// }


.selectbox__list::-webkit-scrollbar {
  height: 15px;
  width: 8px;
 
}

.selectbox__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(177, 177, 177);
  border-radius: 10px;
  margin-right: 189px;
  
}

.selectbox__list::-webkit-scrollbar-thumb {
  background: #0091ff;
  border-radius: 10px;
 
}