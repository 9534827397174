.add__step{
    font-family: Rubik;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: 0.26px;
    margin-top:10px;
    color: #0091ff;
    margin-right: 18px;
    width: 80px;
    cursor: pointer;
    position: absolute;
} 

.project__steps__container{
    // overflow-y: auto;
    // height: 353px;
    // width: 509px;
    .validate__message{
      margin-top: 1px;
    }
}

.project__steps__header {
    opacity: 0.6;
    font-size: 15px;
    color: #000000;
    margin-bottom:15px;
}


// .project__steps__container::-webkit-scrollbar {
//     width: 10px;
//   }


//   .project__steps__container::-webkit-scrollbar-track {
//     background: #f1f1f1; 
//   }
   
//   .project__steps__container::-webkit-scrollbar-thumb {
//     background: #888; 
//   }
  
//   .project__steps__container::-webkit-scrollbar-thumb:hover {
//     background: #555; 
//   }


  .project__steps__container::-webkit-scrollbar {
    height: 15px;
    width: 8px;
   
  }
  
  .project__steps__container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(177, 177, 177);
    border-radius: 10px;
    margin-right: 189px;
    
  }
  
  .project__steps__container::-webkit-scrollbar-thumb {
    background: #0091ff;
    border-radius: 10px;
   
  }