.month-view-page__calendar_infiniteScroll_container > div {
    display: flex;
    flex-wrap: wrap;
}

.month-view-page__calendar__table__day_css2{
    min-height: 210px;
}

.month-view-page__calendar__table__day__process_css2{
    padding: 0px 5px 0px 26px !important;
}

.sticky_header {
    position: sticky;
    top: 0px;
    z-index: 100;
}

.z-index-100000{
    z-index: 100000;
}


@media screen and (max-width: 800px){
    /* month-view-page__calendar__table__day header>p: first-of-type */
    .month-view-page__calendar__table__day header p {
        display: none !important;
    }
}