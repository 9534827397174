.column__container {
  width: 124px;
  display: flex;
  flex-direction: column;
  // margin-left: 3px;
  // margin-right: 1px;
  background-color: #f3f3f3;
  &:first-of-type {
      margin-right:15px;
  }
 
  .task__list {
    // min-height: 37px;
    min-width: 130px;
    position: relative;
    padding: 2px;
    height: 100%;
  }

}

#column-1{
//   padding-right:15px;
}
