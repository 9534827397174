$dark-blue: #243748;
$gray-border: #b7bdc2;
$gray-background: #f7f7f7;
$faded-black: rgba(0, 0, 0, 0.6);
$extra-faded-black: rgba(0, 0, 0, 0.2);
$very-light-blue: rgba(0, 145, 255, 0.1);
$blackish-text: #243748;
$faded-gray-bg: rgba(36, 55, 72, 0.05);
$underlined-blue: #0091ff;
$popup-bg: rgba(0, 0, 0, 0.6);
$warning: #ff0000;
$faded-bluish-border: rgba(36, 55, 72, 0.5);
$hover-buttom-blue: #40acff;
$dark-gray-bg: #efefef;
$main-app-blue: #0091ff;
$main-app-blue-faded: rgba(0, 145, 255, 0.2);
$gray-background2: #f1f1f1;

//dropdown
$drop-gray-border: #e2e2e2;
$drop-bg:#f9f9f9;
$neon-green: #6dd400;
$neon-green-strong: #61bd4f;
$light-neon-green: #cef8c1;
$neon-greenish: rgba(68, 215, 182, 0.3);