.footer__main {
    background-color: #F2F7FB; //#F9F9F9
    width: 100%;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    padding: 10px 16px 24px 16px;
}

.footer__btnContainer {
    width: 100%;
    display: flex;
    height: 36px;
    position: relative;
}
