.task__container {
  position: relative;
  width: 128px;
  display: flex;
  height: 38px;
  border-radius: 18px;
  margin-top: 9px;
  margin-bottom: 9px;
  font-family: Rubik;
  font-size: 14px;
  color: #243748;
  border: solid 1px rgba(36, 55, 72, 0.15);
  cursor: pointer;
  align-items: center;

  &__backlog {
    // background-image: linear-gradient(45deg,rgba(255,255,255,.50) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.50) 50%,rgba(255,255,255,.50) 75%,transparent 75%,transparent) !important;
    background-size: 1.4rem 1.4rem !important;
  }

  .red__circle {
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
    // top: 12px;
    left: 0px;
    position: absolute;
    border: solid 1px #ffffff;
    display: flex;
    align-self: flex-start;
  }

  .drag__icon__container {
    display: flex;
    margin-right: 2px;
    z-index: 1;
    padding: 3px;
  }

  .process__data__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 8px;
    justify-content: center;
    align-content: center;
    margin: auto;
    padding-left: 13px;
  }

  .process__popup__icon__container {
    padding-left: 6px;
    opacity: 0.3;
    border-radius: 100px;
    cursor: pointer;

    img {
      width: 8px;
      height: 14px;
    }
  }
}

.warning__border {
  border: solid 1px red !important;
}



.process__line {
  width: 60px;
  height: 3px;
  margin-left: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1.5px;
  border-radius: 1.5px;
  display: flex;
}
.process__line__finished {
  height: 3px;
  background-color: #050505;
  width: 11px;
}


.task__container:hover {
  border: solid 1px #243748;
  box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.3);
}

.lock__icon__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 4px;
  position: absolute;
  left:3px;
  top:50%;
  transform: translateY(-50%);
  bottom: 1px;

  & > svg {
      font-size: 14px;
  }
}

#task__container {
 
  height: 0px;
  margin: 0;
  border: 0px;
  
  .lock__icon {
    display: none;
  }
  .process__data__container {
    display: none;
  }
  .drag__icon__container {
    display: none;
  }
}

#first__uncomplete__task {
  display: flex;
  // height: 38px;
}

.is__dragging__droppable {
  width: '10px';
}


.draggable {
  top: auto !important;
  left: auto !important;
}