.strap__main {
    background: #EEEEEE;
    direction: ltr;
    padding: 10px;
    display: flex;
    width: 100%;
    height: 60px;
    padding: 5px 10px;
    align-items: center;
    justify-content: space-between;
}

.strap__colLeft {
    display: flex;
    justify-content: left;
    height: 36px;
    align-items: end;
}

.strap__colRight {
    display: flex;
    justify-content: right;
    align-items: center;
}

.strap__iconContainer {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #BBBBBB;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: 5%;
}

.strap__icon {
    font-size: 20px;
    color: #0091FF;
}

.strap__select {
    display: flex;
    align-items: center;
    // padding-bottom: 20px;
}

.addOrderPopup__position {
    position: absolute;
    top: 9%;
    width: 100%;
    height: auto;
    left: 50%;
    transform: translateX(-40%);
    z-index: 2;

    @media screen and (min-width: 1800px) {
        transform: translateX(-25%);
    }
}