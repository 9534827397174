.mobie-navigation{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    padding:0px 16px;
}
.mobiel-navigation__nav-row{
    width: 100%;
    height: 42px;
    border-radius: 20px;
    padding: 12px 10px;
    background-color: #fff;
    border: 1px solid #EEEEEE;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0px 0px 0px 0px #BABABA1A, 0px 1px 2px 0px #BABABA1A, 0px 4px 4px 0px #BABABA17, 0px 10px 6px 0px #BABABA0D, 0px 18px 7px 0px #BABABA03, 0px 28px 8px 0px #BABABA00;
    cursor: pointer;
}

.mobiel-navigation__deatils{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.mobiel-navigation__title{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #455768;
}

.mobiel-navigation__icon-container{
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.mobiel-navigation__icon-container-value{
    border-radius: 50%;
    background-color: #6C7389;
    font-size: 9px;
    line-height: 18px;
    font-weight: 500;
    color: #fff;
}
.mobiel-navigation__icon{
    font-size: 15px;
    color: #6C7389;
}