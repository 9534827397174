@import '../abstracts/mixins.scss';
@import '../abstracts/variables.scss';


.bids__page__container{
    @include displayFlex(row, unset,unset);
}

.bids-page {
    width: 100%;
    // overflow: auto;
    height: 80vH;
    // min-height: calc(100vh - 100px);
    // background-color: #f7f7f7;
    // padding: 0 10px;
    // padding-top:25px;

    & .bids-tabs {

        font-size: 1rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        // line-height: 1.334;
        letter-spacing: 0em;

        // font-size: 30px;
        // font-weight: 300;
        // color: #000000;
        // margin-bottom:25px;

        & > header {
            width:100%;
            height: 54px;
            background-color: $very-light-blue;
            @include displayFlex(row, space-between,center);
            padding: 0 14px;
            flex-shrink: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);

            & > span {
                width: 100%;
                display: -webkit-inline-box;
            }
        }
    }

.bids-tabs-prev {
    margin-top: 2%;
}

    & .bids-tabs-prev > header {
        @include displayFlex(row, space-between,center);
        margin-bottom:15px;

        & > div {
            text-align: right;
            padding: 0 5px;
            width: calc(100% / 7);
            font-size: 14px;
            letter-spacing: 0.25px;
            color: rgba(0, 31, 64, 0.6);

            .sort__arrow__icon{
                position: relative;
                right: 3px;
                top: 2px;
            }
        }

        & > div:first-of-type {
            margin-right:10px;
        }
    }


    &__orders {
        @include displayFlex(column, center,center);
        overflow: auto;
        height: 62vh;
        justify-content: revert !important;

        &__bid {
            @include displayFlex(row, space-between,center);
            width: 100%;
            height: 36px;
            border-radius: 4px;
            border: solid 1px #cfcfcf;
            background-color: #ffffff;
            margin-bottom:3px;
            position: relative;
            &:hover{
                box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.3);
            }
        }

        &__bid > div {
            width: calc(100% / 7);
            font-size: 15px;
            letter-spacing: 0.26px;
            color: #001f40;
            padding: 0 5px;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &__bid > div:first-of-type {
            margin-right:10px;
        }

        &__bid > button {
            position: absolute;
            left:7px;
            width: 38px;
            height: 22px;
            border-radius: 11px;
            border:none;
            background-color: rgba(0, 145, 255, 0.1);

            font-size: 13px;
            line-height: 1.62;
            letter-spacing: 0.23px;
            color: #0091ff;

            &:hover{
                background-color: rgba(0, 145, 255, 0.205);
            }
        }

        .drop__down__box{
            width: unset;
        }


        .drop__down__header__select{
            background-color: unset;
            border: unset;
            width: 70px;
            padding-right: unset;

        }

        #drop__down__box--active li{
            padding-left: 12px;
        }

        .drop__down__list{
            position: absolute;
            width: fit-content;
            padding: 11px 3px;

            li{
                padding-right: 12px;
                padding: 7px; 
                padding-left: 12px;

                span{
                    margin-right: unset;   
                }
      
            }
        }


        .drop__down__list .drop__down__option{

            width: 100%;
            span{
                margin-right: unset;
            }
        }
        
        
       

      
       
    }

    .no__bids{
        margin-top: 20vh;
        font-size: 30px;
    
    }

    .bids-tabs {
        display: flex;
    }

    .bids-tabs-heading {
        margin: 1%;
        cursor: pointer;
    }

    .active-tab-text {
        font-weight: bold !important;
    }

    .active-tab {
        display: block;
    }

    .inactive-tab {
        display: none;
    }

}