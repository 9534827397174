@import '../abstracts/mixins';
@import '../abstracts/variables';

.quote__bids__table{
    @include displayFlex(row, unset,unset);
    width: 100%;
}

.no__quotes{
    margin-top: 20vh;
    text-align: center;
    font-size: 30px;
}
.quote_price_loader {
    position: relative;
    top: 32%;
    right: 3%;
}

// .drop__down__header__select {
//     font-size: 12px;
// }