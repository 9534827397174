.Empty-container{
    width: 100%;
    height: 300px;
    // height: 677px;
    border: 1px solid #CACBCC;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin-top: 10px;
    direction: ltr;
}
.Empty-container p{
  color: #6C7389;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}