
@import '../abstracts/mixins.scss';
@import '../abstracts/variables.scss';

.login__container {
  background-color: rgba(36, 55, 72, 0.1);

  height: 100vh;

  .img__background {
    width: 50%;
    background-size: cover;
    background-position: center;
    position: absolute;
    // z-index: 1;
    height: 100vh;
    display: flex;
    left: 0;
    @include mediaMaxWidth(800px){
        width:100%;
    }
  }

  .login__box__container {
    width: 600px;
    height: 400px;
    border-radius: 20px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translate(-38.18%, -50%);
    top: 50%;
    @include mediaMaxWidth(800px){
        transform: translate(-50%, -50%);
        @include displayFlex(column, center, center);
        flex-direction: row-reverse;
        width: 343px;
        flex-direction: column;
    }

    & > form {
        flex-grow: 1;
        margin-top: 85px;
        padding: 0 45px;
        @include displayFlex(column, center, center);
        justify-content: unset;
        @include mediaMaxWidth(800px){
            margin-top: 95px;
        }

        

        & > button {
            width: 100%;
            height: 36px;
            border-radius: 20px;
            background-color: #0091ff;
            border:none;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.14;
            letter-spacing: 1.25px;
            color: #ffffff;
            transition: transform .2s;
            @include mediaMaxWidth(800px){
                margin-top: 13px;
            }


            &:hover {
                transform: scale(1.1);
                transition: transform .2s;
            }
        }
    }

    &__form-control {
        width:100%;
        @include displayFlex(column, center, flex-start); 
        margin-bottom:30px;

        & > h1 {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
            margin-right: 6px;
            @include mediaMaxWidth(800px){
                margin-right: 0px;
            }

           
        }

        & > input {
            border:none;
            align-self: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            width:96%;
            padding-bottom:10px;
            margin-top:10px;
            font-size:17px;
            font:inherit;
            @include mediaMaxWidth(800px){
                width: 281px;
            }
            

            &::placeholder {
                color: rgba(0, 0, 0, 0.3);
            }
        }

    }

    & > figure {
        width: 229px;
        height: 100%;
        position: relative;
        @include mediaMaxWidth(800px){
            height: 20%;
        }
        

        & > img {
            position: absolute;
            top:0;
            left:0;
            height: 100%;
            z-index: -1;
            @include mediaMaxWidth(800px){
                display: none;
            }
        }

        & > div {
            width: 229px;
            height: 56px;
            background-color: #e9ebed;
            display: block;
            z-index: 1;
            border-top-left-radius: 20px;
            @include displayFlex(row, center, center);
            @include mediaMaxWidth(800px){
                border-top-right-radius: 20px;
                width: 344.5px;
                left: 57px;
                position: relative;
                bottom: 323px;
            }
        }

        & > div > img {
           width: 120px;
        }
    }
  }

}

.login__validation{
    margin-top: 34px;
    color: red;
    @include mediaMaxWidth(800px){
        margin-top: 26px;
    }
}

