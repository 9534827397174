
// wrong implementation , to be changed though
.side__bar__container {
  width: 150px;
  padding-top: 25px;
  direction: ltr;

  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.562);
  }

  li {
    margin-bottom: 15px;
    padding: 8px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.562);
  }

  li:hover {
    color: black;
  }

  #active__sidbar__tab {
    color: black;
    background-color: #f1f1f1;
  }
}

@media screen and (max-height: 580px) {
  .side__bar__container {
   height: 400px;
  //  height: var(--screen-height);
    overflow-y: scroll;
    float: right;
    padding-bottom: 60px;
  }
}
