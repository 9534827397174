.blackStrap__main {
    width: 100%;
    height: 30px;
    background: #243748;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    direction: ltr;
    padding: 0px 10px;
}

;

.blackStrap__countDown {
    background-color: #61BD4F;
    color: #fff;
    display: flex;
    align-items: center;
    width: 100px;
    height: 20px;
    font-size: 10px;
    font-weight: 800;
    line-height: 18px;
    justify-content: center;
    border-radius: 2px;
}

.blackStrap__iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blackStrap__icon {
    font-size: 10px !important;
    font-weight: 800;
    line-height: 18px;
}

.blackStrap__dateSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
}

.blackStrap__iconContainer__calendar {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #0091FF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

// .blackStrap__icon__calender{
//     color: #fff;
//     font-size: 16px !important;

// }
.blackStrap__dateSelector__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
    width: 162px;
    justify-content: center;
}

.blackStrap__dateSelector__date label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}

.blackStrap__backBtn {
    height: 20px;
    display: flex;
    align-items: center;
}