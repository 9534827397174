@import "../abstracts/mixins";
@import "../abstracts/variables";

.popup__error {
  position: fixed;
  z-index: 1001;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.37);
  width: 100%;
  height: 100%;
  top: 0;

  .custom__popup__error {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    top: 25%;
    bottom: 25%;
    z-index: 100;
    @include displayFlex(column, flex-start, center);
    cursor: pointer;

    p {
      text-align: center;
      margin-bottom: 20px;
      font-size: 16px;
      color: black;
    }
    .custom__popup__error__content {
      width: 360px;
      margin-top: 50px;
      padding: 30px;
      height: auto;
      border-radius: 6px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
      z-index: 300;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
     top: 20%;
    }

    button {
      width: 100px;
      height: 36px;
      border-radius: 100px;
      border: none;
      font: inherit;
      background-color: #0091ff;
     
      font-size: 14px;
      color: #ffffff;
    }

    button:hover {
        background-color: $hover-buttom-blue;
      }
  }
}
