


.projects__box__container {
  display: flex;
  background-color: #f3f3f3;

  .side__bar__box:hover {
    background-color: #F3F5F6 ;
  }
  .side__bar__box{
    cursor: pointer;
    width: 189px;
    border-left: 1px solid #919ba3;
    border-top: 1px solid #AEAEAE;
    background-color: white;
    font-family: Rubik;
    /* position: relative; */
    position: sticky;
    right: 0;
    z-index: 30;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

.side__bar__box__messege{
    right: 67px;
    position: relative;
    line-height: 1.17;
    letter-spacing: normal;
    font-size: 12px;
    // width: 35px;
    width: fit-content;
    height: 15px;
    border-radius: 7.5px;
    background-color: #ffda73;
}
   

  }

  .side__bar__box__container {
    // border-left: 1px solid #919ba3;
    // background-color: white;
    font-family: Rubik;
    // position: sticky;
    // right: 0;
    display: flex;
    // align-items: center;
    width: 189px;

    width: 189px;
   
    // border-left: 1px solid #919ba3;
    // border-top: 1px solid #e4e5e6;
    // background-color: white;
    // font-family: Rubik;
    // position: sticky;
    // // right: 0;
    // z-index: 2;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // justify-content: center;
    
    .side__bar__box__text {
      display: flex;
      flex-direction: column;
      width: 130px;
      text-align: right;
      padding-right: 14px;

      .employees__section{
        display: flex;
        & .employess-section{
          width: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
    }

    .side__bar__box__alert {
      display: flex;
      width: 58px;
      padding-right: 31px;
    }

    .side__bar__box__client {
      cursor: pointer;
      font-size: 14px;
      color: #0091ff;

      div {
        margin-bottom: 6px;
      }

      
      div:nth-of-type(1) {
        margin-top: 6px;  
      }

     
    }
    .side__bar__box__manager {
      font-size: 12px;
      color: #888888;
      display: flex;
      margin-top: 4px;
    }

    .side__bar__box__other_info {
      font-size: 12px;
      color: #888888;
      display: flex;
      margin-top: 4px;
      white-space: nowrap;
    }

    .side__bar__box__sales__agent{
      font-size: 12px;
      color: #888888;
      display: flex;
      margin-top: 10px;
    }
  }

  .column__wraaper__container {
    display: flex;
    padding: 0px;
    // margin-right: 188px;
    // margin-right: 15px;
    background-color: #f3f3f3;
    border-bottom: 2px solid #AEAEAE;
  }

  .linear_progress{
    margin-top: 9px;
    width: 100%;
  }
}


