.options__datepicker {
  position: relative;
  height: 44px;
  width: 250px;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 0px!important;
}
.datepicker__label {
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 10px;
  z-index: 999;
  pointer-events: none;
  transition: 0.2s all;
}
#datepicker__label__active {
  font-size: 14px !important;
  color: #0091ff;
  transform: translate(0, -15px);
}
.options__date__separator {
  margin-left: 10px;
  margin-right: 10px;
}

.react-datepicker-popper {
  transform: none !important;
  transform: translate(15px, 35px) !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  padding-right: 1px;
  max-width: 27px;
  max-height: 27px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  color: white !important;
}

.options__datepicker {
  width: 100%;

  img{
    position: relative;
    margin-top: 11px;
    margin-right: 37px;
  }
}

.options__datepicker aside {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #e2e2e2;
  background-color: #f9f9f9;
  font-family: Rubik;
  font-size: 16px;
  text-indent: 5px;
  margin-bottom: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  cursor: pointer;
}

.popup__inner .popup__right .form__container div {
    font-size: 14px;
}


.options__datepicker aside h4 {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.payment__select__hour__active {
  transform: scaleY(1) !important;
}

.react-datepicker__input-container {
  opacity: 1;
  cursor: pointer;
  border: none;
//   max-width: 135px;
  height: 100%;
  cursor: pointer;
  z-index: 10000;
}
.react-datepicker__input-container input {
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
}

.react-datepicker__input-container input[type="text"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: Rubik;; /* Set the font family */
  color: #333; /* Set the text color */
}

.react-datepicker__day--disabled {
  opacity: 0.6;
  pointer-events: none;
}
.react-datepicker__header {
  background: transparent !important;
  border-bottom: 0px !important;
}
.react-datepicker__current-month {
  text-align: right;
  padding-right: 17px;
  position: relative;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background: #0091ff;
  border-radius: 50%;
  color: white !important;
  transition: 0.2s;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: normal;
  color: black;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  display: none;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  display: none !important;
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 5px;
  right: 0px;
  left: auto;
}
.react-datepicker {
  font-family: var(--book-font) !important;
  border: 0px !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15) !important;
  background-color: white;
  border-radius: 4px !important;
  // bottom: 278px;
  // right: 14px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  border-radius: 50%;
  color: black;
}
.react-datepicker__day--weekend {
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #0091ff !important;
  border-radius: 50% !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  color: white;
  background-color: #0091ff !important;
}

.react-datepicker__navigation{
  text-indent: 1px !important;
  z-index: 1;
}

.react-datepicker__navigation--previous {
  left: 50px;
  border: 0px;
  border-top: 2px solid gray;
  border-left: 2px solid gray;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.react-datepicker__navigation--next {
  right: auto;
  left: 20px;
  border: 0px;
  border-top: 2px solid gray;
  border-right: 2px solid gray;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.react-datepicker__current-month::after {
  position: absolute;
  content: "";
  border-top: 2px solid gray;
  border-right: 2px solid gray;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  height: 10px;
  width: 10px;
  left: 20px;
  opacity: 0.3;
  top: 1px;
  pointer-events: none;
}

.react-datepicker__header__dropdown {
  margin-bottom: 10px;
  margin-top: 20px;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  min-height: 200px;
  height: 250px;
  overflow-y: auto;
}
.react-datepicker__year-read-view--down-arrow {
  display: block;
  top: 6px;
  right: 57px;
  transform: scale(0.7);
}


.react-datepicker__year-select{
  position: absolute;
  
  top: 39px;
  font-family: Rubik;
  font-size: 16px;
  right: 84px;
  padding: 4px;
  border-color: #fff transparent transparent transparent;
}


.date__picker__container{
  height: 82px;

  .validate__message{
    margin-top: 0px !important;
  }
}
