  .system-edit-picker {
    width: 100%;
    /* height: 48px; */
    border-radius: 4px;
    background-color: #f9f9f9;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    padding-right: 19px; }
    .system-edit-picker > section {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .system-edit-picker> section > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      height: 100%; }
      .system-edit-picker > section > div > label {
        color: #0091ff;
        font-size: 12px; }
      .system-edit-picker > section > div > input {
        border: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.15);
        background: transparent;
        font-size: 14px;
        padding-right: 15px; }
    .system-edit-picker__delete-section {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      justify-content: flex-start !important; }
      .system-edit-picker__delete-section > svg {
        margin-left: 8px;
        cursor: pointer; }
  
    .system-edit-picker__section1 {
      width: 365px;
      font-size: 14px; }
      .system-edit-picker__section1 > h3 {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid rgba(0, 0, 0, 0.15);
        cursor: pointer; }
        .system-edit-picker__section1 > h3 > span > svg {
          height: 20px !important;
          width: 20px !important; }
      .system-edit-picker__section1__dropdown {
        transform: scaleY(0);
        transition: transform 0.2s;
        transform-origin: top;
        position: absolute;
        top: calc(100% + 3px);
        right: 0;
        padding: 11px 3px;
        width: 100%;
        min-width: 150px;
        border-radius: 5px;
        box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        z-index: 10;
        max-height: 174px;
        overflow-y: scroll;
        max-height: 200px;
        overflow-y: scroll; }
      .system-edit-picker__section1__dropdown--active {
        transform: scaleY(1);
        transition: transform 0.2s; }
      .system-edit-picker__section1__dropdown > li {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-right: 12px;
        background-color: #fff;
        cursor: pointer;
        font-size: 14px; }
      .system-edit-picker__section1__dropdown > li:hover {
        background-color: #f9f9f9; }
    .system-edit-picker__section2 {
      width: 80px;
      padding: 0 20px; }
      .system-edit-picker__section2 > input {
        width: 100%; }
      /* .system-edit-picker__section2::after {
        content: '%';
        position: absolute;
        right: 20px;
        bottom: 3px;
        color: rgba(0, 0, 0, 0.15); } */
    .system-edit-picker__section4 {
      justify-content: center !important;
      margin-right: 10px;
      cursor: pointer; }
      .system-edit-picker__section4 > svg {
        width: 20px;
        height: 20px; }


.system-edit-picker__for_input{
  width: 100%;
  padding: 10px 0;
}

.system-edit-picker__for_input>input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e2e2e2 !important;
    background-color: #f9f9f9;
    cursor: pointer;
    /* display: flex; */
    /* flex-direction: row; */
    padding: 0 10px;
}

.system-edit-picker__for_input>label {
  margin-bottom: 2px;
}