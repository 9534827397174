.addOrderPopUp__main {
    max-width: 1181px;
    width: 1181px;
    background-color: #F9F9F9;
    z-index: 10;
    position: absolute;
    top: 0px;
    // left: calc((100vw/2) - 620px);
    border-radius: 20px;
    padding: 32px;
    direction: rtl;
    opacity: 1.0;  
    overflow-y: scroll;
    height: 1100px;
    left: 50%;
    transform: translateX(-50%);
}
.addOrderPopUp__main-tablet {
    left: 50%;
    width: 1181px;
    transform: translateX(-50%);
}
.addOrderPop__header{
    display: flex;
    justify-content: space-between;
    height: fit-content;
    width: 100%;
}
.addOrderPopUp__headerRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.addOrderPopUp__headerRow span{
   width: 30px;
   height: 30px;
   background-color: #006DC0;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
}
.addOrderPop__icon-container{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #0091FF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.addOrderPop__clear-icon{
    font-size: 30px !important;
    color: #fff;
    cursor: pointer;
}

.addOrderPopUp__name {
    font-size: 24px;
    font-weight: 800;
    line-height: 18px;
    color: #006DC0;
    background-color: transparent;
    margin-bottom: 30px;
}
.addOrderPopUp__navigation{
    margin-bottom: 20px;
    width: 100%;
    height: fit-content;
}

.addOrderFooter{
    position: absolute;
    bottom: 32px;
    left: 32px;
    width: calc(100% - 64px);
}