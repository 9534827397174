.img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.img-container label {
    color: #0091FF;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    @media screen and (max-width: 450px) {
        font-size: 12px;
    }
}

.img__iconContainer {
    background-color: transparent;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 450px) {
        width: 18px;
        height: 18px;
    }
}

.img__icon {
    color: #0091FF;
    font-size: 18px;
}