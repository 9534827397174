.mobileLanding {
    background: #EEEEEE; //#BBBBBB;
    width: 100%;
    height: 100vh;
}

.mobileLanding__dataContainer {
    //padding: 16px !important;
    position: relative;
}

.mobileLanding__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.mobileLanding__page{
  width: 100%;
  position: absolute;
  top: 0;
}