.inputContainer {
    // width: 100%;
    height: 2rem;
    display: flex;
    flex-direction: column;
    padding: auto 0px;
    margin-top: 5px;
    margin-left: 5px;
    flex-grow: 1;
    font-family: Rubik !important;

    @media screen and (max-width: 450px) {
        height: 64px !important;
        // margin-top: 20px !important;
    }
}

.inputContainer--tablet {
    height: 98px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 74px !important;
    }
}

.inputContainer label {
    font-size: 0.7rem;
    font-weight: 800;
    color: #6C7389;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 450px) {
        font-size: 12px !important;
        margin-bottom: -4px;
    }
}



.inputContainer--tablet label {
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px !important;
    }
}

.inputContainer input {
    margin-top: 0px;
    height: 2rem;
    border: 1px solid #CACBCC;
    background-color: #ffffff;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    // color: #CACBCC;
    color: #455768;
    line-height: 18px;
    padding: 14px 16px;
    font-family: Rubik !important;

    &::placeholder {
        color: #CACBCC;
        opacity: 1;
        /* Firefox */
    }

    &::-ms-input-placeholder {
        /* Edge 12-18 */
        color: #CACBCC;
    }

    @media screen and (max-width: 450px) {
        // margin-top: 0px;
        margin-top: 10px;
        height: 42px !important;
        font-size: 12px !important;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 2rem !important;
        font-size: 16px !important;
    }
}

.inputContainer--tablet input {
    height: 70px;
    font-size: 24px;
    margin-top: 10px;
}

.input__error {
    border: 1px solid red;
}

.input--removeArrows {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}