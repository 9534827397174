.digitalSignaturePopUp__signature__container {
    color: #455768;
    direction: ltr;
    display: flex;
    justify-content: end;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 16px;
    position: relative;
}

.digitalSignaturePopUp__btn__container {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.canvas {
    height: 242px;
    width: 100%;
    border: 1px dashed #CACBCC;
    border-radius: 10px;
    padding: 12px 16px;


    @media screen and (min-width: 450px) {
        margin-bottom: 50px;
    }

    @media screen and (max-width: 450px) {
        height: 517px;
    }
}

@media screen and (max-width: 450px) {
    .digitalSignaturePopUp__emptyBox {
        height: calc(650px - 517px);
        width: 100%;
    }
}


// for details view in mobile

.digitalSignaturePopUp__clearBtn {
    position: absolute;
    right: 21px;
    top: 18px;
    width: 90px;
    direction: rtl;
    display: flex;

    @media screen and (min-width: 450px) {
        display: none;
    }
}



.signatureContainer {
    width: 966px;
    height: 100vh;
    background-color: #F9F9F9;
    ;
    z-index: 9999;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 20px;
    padding: 32px;
    direction: rtl;

    @media screen and (max-width: 450px) {
        width: 100%;
        left: 0;
        border-radius: 0px;
        height: 100vh;
    }
}

.signatureContainer--tablet {
    width: 966px;
    height: 100vh;
    background-color: #F9F9F9;
    ;
    z-index: 9999;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 20px;
    padding: 32px;
    direction: rtl;

    @media screen and (max-width: 450px) {
        width: 100%;
        left: 0;
        border-radius: 0px;
        height: 100vh;
    }

    @media screen and (min-width: 1000px) {
        left: calc((100vw/2) - 480px);
        height: 740px;
        // height: calc((100vw/2) + 200px);
    }
}

.canvas-desktop {
    height: calc(100vh - 174px);
    width: 100%;
    border: 1px dashed #CACBCC;
    border-radius: 10px;
    padding: 12px 16px;


    @media screen and (min-width: 450px) {
        // margin-bottom: 50px;
        margin-bottom: 5px;
    }

    @media screen and (max-width: 450px) {
        height: 517px;
    }
}

.canvas-tablet {
    height: 550px;
    width: 100%;
    border: 1px dashed #CACBCC;
    border-radius: 10px;
    padding: 12px 16px;

    @media screen and (min-width: 450px) {
        // margin-bottom: 50px;
        margin-bottom: 5px;
    }

    @media screen and (max-width: 450px) {
        height: 517px;
    }
}

.digitalSignaturePopUp__clearBtn--desktop {
    position: absolute;
    right: 21px;
    top: 18px;
    width: 174px;
    direction: rtl;
    display: flex;
}