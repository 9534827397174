.system__main {
    // display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
}

.system__dataContainer {
    padding: 2px 20px 2px 16px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.system__dataContainer::-webkit-scrollbar-track {
    background-color: transparent;
    border: none;
    margin: 0px;
}

.system__dataContainer::-webkit-scrollbar {
    width: 5px;
    height: 50px;
}

.system__dataContainer::-webkit-scrollbar-thumb {
    margin: 15px;
    border-radius: 4px;
    background-color: #006DC0;
}

@media screen and (max-width: 450px) {
    .mobileView {
        display: block;
    }

    .desktopView {
        display: none;
    }
}

@media screen and (min-width: 451px) {
    .mobileView {
        display: none;
    }

    .desktopView {
        display: block;
    }
}