.addFile__uploaded-contianer {
    width: 100%;
    height: 98px;
    display: flex;
    flex-direction: column;
    padding: auto 0px;
    margin-top: 30px;

    // @media screen and (min-width: 601px) and (max-width: 1000px) {
    //     height: 52px;
    // }
}

.addFile__uploaded-contianer label {
    font-size: 24px;
    font-weight: 800;
    color: #6C7389;
    line-height: 18px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
    }
}

.addFile__uploaded-infoContainer {
    margin-top: 10px;
    height: 74px;
    border: 1px solid #CACBCC;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 50px;
    }
}

.addFile__titleType {
    display: flex;
    justify-content: right;
    align-items: center;
}

.addFile__titleType label {
    font-size: 24px;
    font-weight: 400;
    color: #455768;
    line-height: 18px;
    display: flex;
    justify-content: right;
    margin-right: 10px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 18px;
    }
}

.addFile__remove {
    display: flex;
    justify-content: left;
    cursor: pointer;
}

.addFile__icon-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #0091FF;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 30px;
        height: 30px;
    }
}

.addFile__uploaded-icon {
    color: #fff;
    font-size: 24px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 17px;
    }
}

.addFile__inputRow--margin {
    margin-bottom: 0px;
}

.addfile__uploadFile {
    margin-bottom: 80px;

    @media screen and (max-width: 450px) {
        margin-bottom: 0px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        margin-bottom: 0px;
    }
}