.system__container {
    width: 100%;

    @media screen and (max-width: 600px) {
        height: calc(100vh - 150px);
        background: #EEEEEE;
    }
}
.systems__list {
    width: 100%;
    height: calc(100vh - 350px);
    overflow-y: auto;
    margin-bottom: 120px;
}
.systems__list--addOrder {
    width: 100%;
    height: calc(100vh - 100px);
    overflow-y: auto;
    margin-bottom: 120px;
}