.addSystemMeasurement__measurement {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  top: 60px;
  margin-bottom: 5px;

}

.addSystemMeasurement__measurement label {
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  color: #6C7389;
  text-align: center;
}

.addSystemMeasurement__inputs {
  padding: 0px;
  display: flex;
  flex-direction: row;

  // justify-content: center;
  position: relative;

}

.addSystemMeasurement__dot {
  position: relative;
  top: 33px;
  left: 5px;
  width: 10px;
  height: 10px;
  font-size: 30px;
}

.addSystemMeasurement__rightInput {
  direction: ltr;
  margin-left: 10px;
}

.addSystemMeasurement__leftInput {
  margin-right: 5px;
}


// desktop changes
.addSystemMeasurement__inputRow{
  width: 100%;
  display: flex;
  flex-direction: row;
}