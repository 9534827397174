.header__main{
    width: 100%;
    height: 60px;
    background-color: #ffff;
    box-shadow: 0px 2px 4px 0px #0000000D;
  //  border: 1px solid #CACBCC;
    border-bottom: 1px solid #CACBCC;;
    padding: 0 16px;
}
.header__col{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.heder__brand img{
  max-width: 118px;
  max-height: 32px;
  cursor: pointer;
}

.header__search{
    display: flex;
    justify-content: center;
}

.header__iconContainer{
   width: 36px;
   height: 36px;
   border-radius: 50%;
   background-color: #3C93D6;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

.header__searchContainer{
  //  max-width: 260px;
  width: 50%;
    height: 36px;
    border: 1px solid #BBBBBB;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
}
.header__searchContainer input{
    width: 100%;
    border: none;
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;
    color: #6C7389;
}

.header__account{
    display: flex;
    justify-content: left;
}

.header__account__icons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.header__account__iconsContainer{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    cursor: pointer;
}

.header_verticalLine{
    width: 30px;
    height: 2px; 
    transform: rotate(90deg);
    border: 1px solid #BEBEBE;
}

.header__account__logo{
    width: 128px;
    height: 30px;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.header__notification{
    position: absolute;
    top: 7px;
    right: 5px;
    font-size: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #3C93D6;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}