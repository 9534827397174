.changeSystemPopUp__main {
    direction: ltr;
    max-width: 164px;
    background-color: #fff;
    border-radius: 5px;
    height: auto;
    text-align: left;
    position: absolute;
    top: 55px;
    left: 25px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 99;
    padding-right: 20px;
}

// .changeSystemPopUp__main--tablet {
//     max-width: 240px;
//     direction: ltr;
//     max-width: 164px;
//     background-color: #fff;
//     border-radius: 5px;
//     height: auto;
//     text-align: left;
//     position: absolute;
//     top: 55px;
//     left: 25px;
//     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//     z-index: 99;
// }

.changeSystemPopUp__list__item {
    width: 100%;
    margin: 10px;
    text-align: left;
    padding: 5px;
    display: flex;
    justify-content: left;
    cursor: pointer;
}

.changeSystemPopUp__list__item__icon {
    color: #3C93D6;

}

.changeSystemPopUp__list__item__text {
    color: #455768;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

// .changeSystemPopUp__list__item__text--tablet {
//     font-size: 20px;
//     max-width: 150px;
//     color: #455768;
//     font-weight: 400;
//     display: inline-block;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     text-align: left;
//     border: 1px solid;
// }