.SingleFileDetails__main {
    box-shadow: 0px 0px 0px 0px #A1A1A11A, 0px 1px 2px 0px #A1A1A11A, 0px 4px 4px 0px #A1A1A117, 0px 9px 5px 0px #A1A1A10D, 0px 15px 6px 0px #A1A1A103, 0px 24px 7px 0px #A1A1A100;
    border-radius: 6px;
    width: 100%;
    max-height: 220px;
    border: 1px solid #C4E3FC;
    margin-bottom: 10px;
    padding: 10px 0;
}
.SingleFileDetails__container{
    border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
}

.SingleFileDetails__iconContainer{
    cursor: pointer;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 0px 0px #93A2AE1A, 0px 0px 0px 0px #93A2AE1A, 0px 1px 1px 0px #93A2AE17, 1px 1px 1px 0px #93A2AE0D, 1px 2px 1px 0px #93A2AE03, 1px 4px 1px 0px #93A2AE00;
    padding: 2px;
}