.openedFilePopUp__main {
  max-width: 853px;
  height: 800px;
  background-color: #F9F9F9;
  z-index: 99;
  position: absolute;
  top: 0px;
  left: calc((100vw/2) - 426px);
  border-radius: 20px;
  padding: 25px;
  direction: rtl;

  @media screen and (max-width: 450px) {
    width: 100%;
    left: 0;
    border-radius: 0px;
    height: 100vh;
  }
}

.addFilePopUp__title {
  font-size: 24px;
  font-weight: 800;
  line-height: 18px;
  color: #006DC0;
  background-color: transparent;
  //margin-top: 10px;
  margin-bottom: 30px;

  @media screen and (max-width: 450px) {
    font-size: 20px;
  }
}

.openedFilePopUp__attachment{
  display: flex;
  justify-content: center;
  width: 100%;
}
.openedFilePopUp__fileContainer {
  max-width: 786px;
  height: 618px;
  background-color: #455768;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0px 0px 0px 0px #CCCCCC1A, 0px 1px 2px 0px #CCCCCC1A, 0px 4px 4px 0px #CCCCCC17, 0px 8px 5px 0px #CCCCCC0D, 0px 15px 6px 0px #CCCCCC03, 0px 23px 7px 0px #CCCCCC00;
  overflow-y: scroll;

  @media screen and (max-width: 450px) {
    border-radius: 0px;
    padding: 10px;
    min-height: 478px;
    max-width: 500px;
  }
}

.openedFilePopUp__fileContainer::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
  margin: 20px;

  @media screen and (max-width: 450px) {
    position: absolute;
    left: 15px;
  }
}

.openedFilePopUp__fileContainer::-webkit-scrollbar {
  width: 8px;
}

.openedFilePopUp__fileContainer::-webkit-scrollbar-thumb {
  margin: 15px;
  border-radius: 4px;
  background-color: #CACBCC;
}




.openedFilePopUp__skeletonContainer {
  width: 571px;
  height: auto;
  background-color: #ffffff;
  padding: 20px;
  margin: 0 auto;

  @media screen and (max-width: 450px) {

    padding: 15px;
  }
}

.openedFilePopUp__skeletonContainer__name {
  font-size: 24px;
  font-weight: 800;
  line-height: 18px;
  color: #006DC0;
  background-color: transparent;
  margin: 30px;
  text-align: center;

  @media screen and (max-width: 450px) {
    font-size: 20px;
    margin: 3px;
    margin-bottom: 50px;
  }
}

.openedFilePopUp__skeletonContainer__fullBox {
  width: 100%;
  height: 136px;
  background-color: #CACBCC;
  margin-bottom: 16px;

  @media screen and (max-width: 450px) {
    height: 58px;
    margin-bottom: 13px;
  }
}

.openedFilePopUp__skeletonContainer__row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.openedFilePopUp__btn__container {
  margin-top: 20px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;

  @media screen and (max-width: 450px) {
    justify-content: space-between;
    height: 36px;
  }
}

@media screen and (max-width: 450px) {
  .openedFilePopUp__skeletonContainer__desktop {
    display: none;
  }

  .openedFilePopUp__skeletonContainer__mobile {
    display: inline-flex;
    flex-direction: column;
  }

  .mobileOnly{
    display: flex;
    flex-direction: row;
  }

  .marginLeft {
    margin-left: 10px;
  }

  .marginRight {
    margin-right: 10px;
  }
}

@media screen and (min-width: 451px) {
  .openedFilePopUp__skeletonContainer__desktop {
    display: inline-flex;
  flex-direction: column;
  }

  .openedFilePopUp__skeletonContainer__mobile {
    display: none;
  }

  .desktopOnly{
    display: flex;
    flex-direction: row;
  }

  .marginLeft {
    margin-left: 10px;
  }

  .marginRight {
    margin-right: 10px;
  }
}




// tablet view css
.openedFilePopUp__main--tablet {
  max-width: 679px;
  height: 1200px;
  background-color: #F9F9F9;
  z-index: 99;
  position: absolute;
  top: 0px;
  left: calc((100vw/2) - 340px);
  border-radius: 20px;
  padding: 50px 42px;
  direction: rtl;
}

.addFilePopUp__title--tablet {
  font-size: 42px;
  font-weight: 800;
  line-height: 18px;
  color: #006DC0;
  background-color: transparent;
  margin-bottom: 30px;

}

.openedFilePopUp__fileContainer--tablet {
  max-width: 595px;
  height: 948px;
  background-color: #455768;
  border-radius: 0px;
  padding: 24px;
  box-shadow: 0px 0px 0px 0px #CCCCCC1A, 0px 1px 2px 0px #CCCCCC1A, 0px 4px 4px 0px #CCCCCC17, 0px 8px 5px 0px #CCCCCC0D, 0px 15px 6px 0px #CCCCCC03, 0px 23px 7px 0px #CCCCCC00;
  overflow-y: scroll;
}

openedFilePopUp__fileContainer--tablet::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
  margin: 20px;

  @media screen and (max-width: 450px) {
    position: absolute;
    left: 15px;
  }
}

.openedFilePopUp__fileContainer--tablet::-webkit-scrollbar {
  width: 8px;
}

.openedFilePopUp__fileContainer--tablet::-webkit-scrollbar-thumb {
  margin: 15px;
  border-radius: 4px;
  background-color: #CACBCC;
}

.openedFilePopUp__skeletonContainer--tablet {
  width: 571px;
  height: auto;
  background-color: #ffffff;
  padding: 20px;
  margin: 0 auto;
}

.openedFilePopUp__btn__container--tablet {
  margin-top: 25px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;

}