.addSystem__main {
    width: 700px;
    background-color: #F9F9F9;
    z-index: 9999;
    position: absolute;
    top: 0px;
    left: calc((100vw/2) - 290px);
    border-radius: 20px;
    padding: 50px 42px;
    direction: rtl;
    opacity: 1.0;
    // min-height: 600px;
    // height: 700px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        position: fixed;
        width: 580px;
        height: fit-content;
        padding: 32px;
        border-radius: 20px;
    }

    @media screen and (max-width: 450px) {
        position: fixed;
        width: 100%;
        height: 100vh;
        padding: 16px 24px;
        border-radius: 0px;
        left: 0px;
    }
}


.addSystem__title {
    font-size: 42px;
    font-weight: 800;
    line-height: 18px;
    color: #006DC0;
    background-color: transparent;
    margin-bottom: 30px;

    @media screen and (max-width: 450px) {
        font-size: 20px;

    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 24px;
    }
}


.addSystem__uploadedInfo {
    border-radius: 6px;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 0px 0px #8787870D, 0px 1px 2px 0px #8787870D, 0px 4px 4px 0px #8787870A, 0px 9px 5px 0px #87878708, 0px 16px 6px 0px #87878703, 0px 25px 7px 0px #87878700;
    margin-bottom: 30px;

    @media screen and (max-width: 450px) {
        height: 80px;
        margin-bottom: 0px !important;
        position: relative;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        margin-bottom: 0px !important;
    }
}

.addSystem__uploadedInfo-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #EBF6FF;
    height: 51px;

    // @media screen and (min-width: 451) and (max-width: 1000px) {
    //         margin-bottom: 0px !important;
    // }
    @media screen and (max-width: 1000px) {
        height: 40px;
        display: flexbox;
    }
}


.addSystem__uploadedInfo-label {
    padding: 6px 16px 6px 0px;
    text-align: right;
}

.addSystem__uploadedInfo-label span {
    color: #006DC0;
    font-size: 20px;
    font-weight: 800;
    line-height: 18px;

    @media screen and (max-width: 450px) {
        font-size: 12px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
}

.addSystem__uploadedInfo-value {
    padding: 6px 0px 6px 16px;
    text-align: left;
}

.addSystem__uploadedInfo-value span {
    color: #6C7389;
    font-size: 20px;
    font-weight: 500;
    line-height: 18px;

    @media screen and (max-width: 450px) {
        font-size: 12px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
}

.addSystem__inputsFields {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 450px) {
        margin-top: 10px;
    }
}

.addSystem__inputsRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 80px;

    @media screen and (max-width: 450px) {
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 450px) {
    .addSystem__inputsLeft {
        margin-top: 10px;
    }
}




.addSystem__btn__container{
    margin-top: 50px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    position: absolute;
    right: 43px;
    bottom: 25px;

    @media screen and (max-width: 450px) {
        right: 20px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        right: 0px;
        position: relative;
        top: 0px;
        margin-bottom: 0px;
        margin-top: 20px;
    }

}



@media screen and (max-width: 450px) {
    .addSystem__emptyBox {
        width: 100%;
        height: 200px;
        border: 1px salmon;
    }
}