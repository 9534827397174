@import '../abstracts/mixins.scss';
@import '../abstracts/variables.scss';

.on-going-orders-report-header {
    width:100%;
    height: 54px;
    background-color: $very-light-blue;
    @include displayFlex(row, space-between,center);
    padding: 0 14px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    & > span {
        width: 15%;
        display: -webkit-inline-box;
    }
}

