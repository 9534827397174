.select-input-container {
    width: 100%;
    height: 74px;
    display: flex;
    flex-direction: column;
    padding: auto 0px;
    margin-top: 6px !important;
    font-family: Rubik !important;

    @media screen and (max-width: 450px) {
        height: 64px !important;
    }
}

.select-input-container--tablet {
    height: 98px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 4rem;
    }
}

.select-input-container label {
    font-size: 0.7rem;
    font-weight: 800;
    color: #6C7389;
    line-height: 18px;
    margin-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 450px) {
        font-size: 12px !important;
        margin-bottom: 5px;
    }
    // @media screen and (min-height: 6001) and (max-width: 450px) {
    //     font-size: 16px !important;
    //    // margin-bottom: 5px;
    // }
}

.select-input-container--tablet label {
    font-size: 1.4rem;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 0.9rem;
    }
}

.select-input-container__form {
    border-bottom: none;
}

.select-input-container__select {
    // height: 2.3rem;
    height: 1.9rem;
    // border: 1px solid #CACBCC;
    // background-color: transparent; 
    background-color: #ffffff;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #455768 !important;
    line-height: 18px;
    // padding: 14px 16px 14px 0px;
    font-family: Rubik !important;
    margin-top: 1px;


    &::placeholder {
        color: #CACBCC;
        opacity: 1;
        /* Firefox */
    };

    &::-ms-input-placeholder {
        /* Edge 12-18 */
        color: #CACBCC;
    }

    @media screen and (max-width: 450px) {
        height: 42px !important;
        font-size: 12px !important;
    }

}

.select-input-container__select--tablet {
    height: 70px;
    font-size: 24px;
    // margin-top: 10px;
    margin-top: 6px;
    font-family: Rubik !important;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 52px !important;
        font-size: 16px !important;
        margin-top: 4px;
    }
}

.multiSelect__menuItem{
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row;
}

.MuiInput-formControl {
    border-bottom: none;
    border: none;
}

.MuiInput-root {
    font-size: 13px !important;
    direction: rtl;
}

.MuiOutlinedInput-notchedOutline {
    border: 0
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: rgb(0, 145, 255) !important;
}