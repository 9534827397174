@import '../abstracts/mixins';
@import '../abstracts/variables';


.product-tab {
    box-shadow: 0px 1px 10px #cdc7c7;
    padding: 30px;
    border-radius: 2px;
    margin-bottom: 20px;
}

.img-container {
    position: relative;
    cursor: pointer;
    height: -webkit-fill-available;
}

.img-container:hover .img-overlay  {
    background-color: #00000065;
    .img-details-btn{
        opacity: 1;
    }
}

.img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;
}

.img-details-btn {
    position: absolute !important;
    top: 40%;
    left: 35%;
    text-align: center !important;
    justify-content: center !important;
    color: #0091ff !important;
    background-color: white !important;
    line-height: normal !important;
    opacity: 0;
}
