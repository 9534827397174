.orderDetails__main{
    width: 100%;
    height: 900px;
    overflow-y: scroll;
}
.orderDetails__checkBtnContainer {
    display: flex;
    justify-content: right;
    width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;
}

.orderDetails__inputRow {
    display: flex;
    justify-content: right;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    margin-bottom: 40px;
    border: 1px solid #EEEEEE;
    padding: 16px 24px;
    border-radius: 12px;
    box-shadow: 0px 0px 0px 0px #B8B8B81A, 0px 1px 2px 0px #B8B8B81A, 0px 3px 3px 0px #B8B8B817, 0px 8px 5px 0px #B8B8B80D, 0px 14px 6px 0px #B8B8B803, 0px 22px 6px 0px #B8B8B800;
}

.orderDetails__inputRow__rowName {
    display: flex;
    justify-content: right;
    align-items: center;
}

.orderDetails__inputRow__rowName span {
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    color: #006DC0;
}

.orderDetails__inputRow__inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.orderDetails__btn__Container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
}