.reviewPopUp__main{
    max-width: 580px;
    background-color:  #F9F9F9;
    z-index: 99;
    position: absolute;
    top: 0px;
    left: calc((100vw/2) - 290px);
    border-radius: 20px;
    padding: 16px;
    direction: rtl;
}
.reviewPopUp__name{
   font-size: 24px;
   font-weight: 800;
   line-height: 18px;
   color: #006DC0;
   background-color: transparent;
   margin-bottom: 30px;
}

.reviewPopUp__btn__contianer{
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.MuiNativeSelect-icon{
    background-color: #0091FF;
    color: red;
    width: 100px;
    height: 100px
}