@import '../abstracts/mixins.scss';
.delete__container {
//   width: 460px;
  height: 45px;
  border-radius: 4px;
  background-color: #f9f9f9;
  display: flex;
 
  margin-top: 3px;
  font-family: Rubik;
  font-size: 16px;

  .step__input__icons {
   
    cursor: pointer;
    padding:0;
    @include displayFlex(row,center,center);

    .fa-times {
      margin-left: 18px;
      cursor: pointer;
    }

    .fa-check {
      color: #000000;
      font-size: 14px;
      margin-left: 16px;
      cursor: pointer;
      margin-right: 16px;

    }
  }
}
