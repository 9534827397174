.paymentDetails__main{
    display: flex;
    justify-content: center;
}

.paymentDetails__btn__container{
    margin-top: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}
.paymentDetails__btn__container__left{
    display: flex;
    justify-content: left;
}
.paymentDetails__btn__container__right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}