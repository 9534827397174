@import '../abstracts/mixins';
@import '../abstracts/variables';

.developer-container {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: flex;
    border-top: 1px solid #243748;

    & .list-section {
        width: 15%;
        height: 100vh;
        border-left: 1px solid darkgray;

        & .saturday-script-btn{
            width: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;
            & > button{
                background: #0091ff;
                border-radius: 21px;
                border: none;
                width: 250px;
                height: 26px;
                font-size: 18px;
                color: white;
                box-shadow: 0px 0px 5px 1px #c4c3c3;
            }
        }
    }

    & .view-section {
        width: 85% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}