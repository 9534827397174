.deleteFile__confirmation--desktop {
    color: #455768;
    direction: ltr;
    display: flex;
    justify-content: end;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 16px;

    @media screen and (max-width: 450px) {
        font-size: 12px;
    }
}

.deleteFile__emptyBox {
    width: 100%;
    min-height: 400px;
    @media screen and (max-width: 450px) {
        display: block;
    }
    @media screen and (min-width: 451px) {
        display: none;
    }
}