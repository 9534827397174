@import '../abstracts/mixins';
@import '../abstracts/variables';


.month-resource-page__completions__draggable:hover{
    box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.3)
}

.month-resource-page {
    @include displayFlex();
    position: relative;

    //POPUP
    &__popup {
        position: fixed;
        top: 0;
        left:0;
        width:100vw;
        height: 100vh;
        z-index: 100;
        @include displayFlex(row,center,center);

        &__curtain {
            background: $popup-bg;
            width:100%;
            height: 100%;
            z-index: 200;
            position: absolute;
            top:0;
            left:0;
        }

        &__content{
            height: 400px;
            width: 600px;
            z-index: 300;
            background-color: white;
            border-radius: 10px;
            @include displayFlex(row,center,center);
        }
    }

    //COMPLETIONS
    &__completions{
        width: 12.25vw;
        height: 100%;
        min-height: calc(100vh - 100px);
        background-color: #fff;
        flex-shrink: 0;
        @include displayFlex(column, flex-start, center);
        @include mediaMaxWidth(800px){
            display: none;
        }
        

        &__droppable {
            flex-grow: 1;
            width: 100%;
            padding: 0 5px;
            overflow-y: scroll;
            height: calc(100vh - 135px);
        }

        & > header {
            width: 100%;
            height: 30px;
            background-color: $very-light-blue;
            @include displayFlex(row, flex-start,center);
            font-size: 18px;
            color: $blackish-text;
            padding: 0 10px;
            margin-bottom:5px;
        }

        &__draggable {
            @include displayFlex(column, center, flex-start);
            width: 100%;
            min-height: 53px;
            border-radius: 5px;
            margin-bottom: 5px;
            padding: 0 10px;
            background-color: $faded-gray-bg;

            & > section > div {
                font-size: 14px;
                margin:5px 0;
            }

            & > section > div:first-of-type {
                text-decoration: underline;
                
                color: $underlined-blue;
            }
        }
    }



    //CALENDER
    &__calendar {
        width: 100%;
        flex-grow: 1;
        @include displayFlex(column);

        &__mobile-days-panel {
            height: 26px;
            display: none;
            @include mediaMaxWidth(800px) {
                @include displayFlex(row,unset,center);
                // border-left: 1px solid #b7bdc2 ;
                // border-right: 1px solid #b7bdc2 ;
                .mobile__days__names__warning{
                    color: red;
                    img{
                        height: 10px;
                        position: relative;
                        right: 3px;
                        top: 1px;
                    }
                }
            }

            

            & > div {
                @include displayFlex(row,center,center);
                @include mediaMaxWidth(800px) {
                    background: linear-gradient(#b7bdc2, #b7bdc2), linear-gradient(#b7bdc2, #b7bdc2), linear-gradient(#b7bdc2, #b7bdc2);
                    background-size: 1px 33%, 100% 1px, 0px 33%;
                    background-position: bottom left, bottom;
                    background-repeat: no-repeat;
                    border: none;
                    height: 100%;
                    background-color: #f7f7f7;
                }
            }

            & > div:nth-of-type(1){
                @include mediaMaxWidth(800px) {
                    background: linear-gradient(#b7bdc2, #b7bdc2), linear-gradient(#b7bdc2, #b7bdc2), linear-gradient(#b7bdc2, #b7bdc2);
                    background-size: 0px 33%, 100% 1px, 1px 33%;
                    background-position: bottom right, bottom , bottom left;
                    background-repeat: no-repeat;
                    border: none;
                    height: 100%;
                    background-color: #f7f7f7;

                }
            }
            

            & > div:last-child{
                @include mediaMaxWidth(800px) {
                    background: linear-gradient(#b7bdc2, #b7bdc2), linear-gradient(#b7bdc2, #b7bdc2), linear-gradient(#b7bdc2, #b7bdc2);
                    background-size: 0px 30%, 100% 1px, 0px 0%;
                    background-position: bottom right, bottom , bottom left;
                    background-repeat: no-repeat;
                    border: none;
                    height: 100%;
                    background-color: #f7f7f7;
                }
            }

            & > div > p {
                font-size: 12px;
                font-weight: bold;
            }
        }

        &__header {
            width: 100%;
            height: 30px;
            z-index: 1;
            position: relative;
            background-color: $dark-blue;
            @include displayFlex(row, center, center);
            @include mediaMaxWidth(800px){
                height: 36px;
            }

            & > button {
                @include backToTodayBtn();
                position: absolute;
                @include mediaMaxWidth(800px){

                    top: -96px;
                    right: 112px;
                    border-radius: 0;
                    height: 56px;
                    width: 56px;
                }
                @include mediaMinWidth(800px){
                    background-color: #0091FF;
                    width: fit-content;
                    height: 20px;
                    border-radius: 16px;
                    padding: 10px;
                    text-transform: uppercase;
                    position: absolute;
                }
            }

            .monthly-resource-arrows {
                width: 11px;
            }

            .back--today--icon {
                @include mediaMinWidth(800px){
                    display: none;
                }
            }
        }

        &__header > h3 {
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
            text-transform: uppercase;
            @include mediaMaxWidth(800px){
                & > span {
                    display: none;
                }
            }
        }
        
        &__header > #mobile__dec__figure{
            display: none;
            @include mediaMaxWidth(800px){
                display: block;
                width: 11px;
                height: 6px;
                // border-radius: 50%;
                // background-color: rgba(255, 255, 255, 0.1);
                margin-left:6px;
                @include displayFlex(row, center, center);
                cursor: pointer;
                position: absolute;
                right:8px;
            }
        }

        &__header > #mobile__dec__figure-left{
            display: none;
            @include mediaMaxWidth(800px){
                display: block;
                width: 11px;
                height: 6px;
                // border-radius: 50%;
                // background-color: rgba(255, 255, 255, 0.1);
                margin-left:6px;
                @include displayFlex(row, center, center);
                cursor: pointer;
                position: absolute;
                left:8px;
            }
        }

        &__header > div {
            @include displayFlex(row);
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            left:6px;

            // & > figure {
            //     width: 18px;
            //     height: 18px;
            //     border-radius: 50%;
            //     background-color: rgba(255, 255, 255, 0.1);
            //     margin-left:6px;
            //     cursor: pointer;
            //     @include displayFlex(row, center, center);
            //     @include mediaMaxWidth(800px){
            //         &:first-of-type{
            //             display: none;
            //             margin-left:0px;
            //         }
            //     }
            // }
        }

        .figure-arrows {
            width: 11px;
            height: 6px;
            // border-radius: 50%;
            // background-color: rgba(255, 255, 255, 0.1);
            margin-left:6px;
            margin-right: 6px;
            cursor: pointer;
            @include displayFlex(row, center, center);
            @include mediaMaxWidth(800px){
                // &:first-of-type{
                    display: none;
                    margin-left:0px;
                // }
            }
        }


        &__table {
            @include displayFlex();
            flex-wrap: wrap;
            flex-grow: 1;
            overflow-y: scroll;
            overflow-x: hidden;
            height: calc(100vh - 130px);
            @include mediaMaxWidth(800px){
                height: calc(100vh - 158px);
            }

            &__day--warning {
                position: relative;
                & > figure{
                    content: '';
                    border:1px solid $warning;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    z-index: 0;
                    @include mediaMaxWidth(800px){
                        border:unset
                        
                    }
                }

                & header p{
                    color: $warning !important;
                    
                }

                header span{
                    @include mediaMaxWidth(800px){
                        color: $warning !important; 
                    }
                }


            }

            &__day {
                width: calc(100% / 7 );
                // height: calc(100% / 4 );
                background: $gray-background;
                border-left: 1px solid $gray-border;
                border-bottom: 1px solid $gray-border;
                position: relative;
                min-height: 116px;

                .today__mark{
                    @include mediaMaxWidth(800px){
                        color: white; 
                        background-color: #0091ff;
                        border-radius: 13.5px;
                        padding: 3px;
                        padding-left: 7px;
                        padding-right: 7px;
                    }
                    
                }

                .warning_icon {
                    width: 16px;
                    top: 2px;
                    position: relative;
                }

                &--holiday {
                    width: 100%;
                    height: 20px;
                    background-color: #cef8c1;
                    @include displayFlex(row,center,center);
                    padding:0 5px;
                    

                    & > p {
                        font-size: 14px;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        text-align: center;
                    }
                }

                &--holiday_space {
                    width: 100%;
                    height: 20px;
                    background-color: transparent;
                    @include displayFlex(row,center,center);
                    padding:0 5px;
                    

                    & > p {
                        font-size: 14px;
                        width: 100%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        text-align: center;
                    }
                }

                &--dayoff {
                    height: calc(100% - 55px);
                    @include displayFlex(row,center,center);

                    & > p {
                        font-size: 16px;
                        color: $faded-black;
                        width:70px;
                        text-align: center;
                        font-weight: bold;
                    }
                }

                &__process {
                    width:100%;
                    height: 38px;
                    border: 1px solid #E2E2E2;
                    background: #ffffff;
                    margin-bottom: 2px;
                    // border-radius: 20px;
                    @include displayFlex(row,unset,center);
                    padding: 0 5px;
                    overflow: hidden;
                    direction: rtl;
                    position: relative;
                    cursor: grab;
                    @include mediaMaxWidth(800px){
                        // height: 41px;
                    }
                    

                    &:hover {
                        // box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3);
                        box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.3);
                    }

                    .process__isdetached__icon{
                        display: none;
                        @include mediaMaxWidth(800px){
                           display: block;
                           position: absolute;
                           display: block;
                           position: absolute;
                           bottom: 27px;
                           right: 74%;
                           img{
                            height: 8px;
                           }
                        }  
                    }

                    &--warning {
                        position: relative;
                        border: 1px solid $warning;
                        @include mediaMaxWidth(800px){
                            // border: unset
                        }

                        .process__warning__icon{
                            @include mediaMaxWidth(800px){
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                border: 1px solid red;
                                background: red;
                                position: absolute;
                                right: 89%;
                                top: 2px;
                            }  
                        }

                        & figure {
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            border: solid 1px #ffffff;
                            background-color: $warning;
                            position: absolute;
                            top:3px;
                            left:3px;
                        }

                        & div {
                            color: $warning;
                            @include mediaMaxWidth(800px){
                                color: unset
                            }
                        }
                    }
                }

                &__process > .process-type-aside {
                    width: 16px;
                    height: 16px;
                    @include displayFlex(row,center,center);
                    margin-left:7px;
                    @include mediaMaxWidth(800px){
                        // display: none;
                    }

                }

                .process-type-aside {
                    padding: 12px;
                    background-color: #455768;
                    border-radius: 50%;
                    z-index: 9;
                    @include displayFlex(row,center,center);
                    @include mediaMaxWidth(800px){
                        padding: 10px;
                        background-color: #455768;
                        border-radius: 50%;
                    }
                }

                .process-type-aside--warning {
                    width: 16px;
                    height: 16px;
                    padding: 12px;
                    z-index: 9;
                    background-color: red;
                    border-radius: 50%;
                    @include displayFlex(row,center,center);
                    margin-left:7px;
                    @include mediaMaxWidth(800px){
                        padding: 10px;
                        background-color: red;
                        border-radius: 50%;
                        // display: none;
                    }
                }

                .process-type-icon {
                    width: 12px;
                    height: 12px;
                    @include displayFlex(row,center,center);
                    @include mediaMaxWidth(800px){
                        width: 12px;
                        height: 12px;
                    }
                }
                .process--resources {
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                &__process > .process--info--aside {
                    width: 16px;
                    height: 16px;
                    padding: 10px;
                    border-radius: 50%;
                    position: absolute;
                    left: 4px;
                    cursor: pointer;
                    @include displayFlex(row,center,center);
                    // margin-left:7px;
                    @include mediaMaxWidth(800px){
                        padding: 10px;
                        border-radius: 50%;
                        position: absolute;
                        left: 4px;
                        cursor: pointer;
                        // display: none;
                    }
                }

                .process--info--icon {
                    width: 16px;
                    height: 16px;
                    @include displayFlex(row,center,center);
                    @include mediaMaxWidth(800px){
                        width: 16px;
                        height: 16px;
                    }
                }

                .process--resource {
                    width: 100%;
                    height: 5.5px;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                &__process > section {
                    max-width: 88%;
                    text-overflow: ellipsis;
                    @include mediaMaxWidth(800px){
                        max-width: 88%;
                    }
                }

                &__process--warning--section > aside {
                    width: 12px !important;
                    height: 12px;
                    border-radius: 50%;
                    border: solid 1px #ffffff;
                    background-color: $warning;
                    position: absolute;
                    left: 7px;
                    top: 2px;
                }

                &__process > section > div {
                    width:100%;

                    @include mediaMaxWidth(800px){
                    .month__process__number {
                        display: none;
                    }
                }

                    
                }

                &__process > section > div > p {
                    font-size: 14px;
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    @include mediaMaxWidth(800px){
                        text-overflow: unset;
                        font-size: 12px;
                         
                        & .month__proccess__employee{
                           display: block;
                        }
                    }
                }


                &__process--menu {
                    width: 13px;
                    height: 12px;
                    border-radius: 100px;
                    @include displayFlex(row,center,center);
                    position: absolute;
                    // left:7px;
                    left: 8px;
                    bottom: 11px;
                    &:hover {
                        background-color: rgba(36, 55, 72, 0.1);
                    }
                    @include mediaMaxWidth(800px) {
                        bottom: 14px;
                    }
                }

                &__droppable {
                    // padding: 0 7px;
                    // height: calc(100% - 30px);
                    // height: unset !important;
                    // overflow-y: scroll;
                    // overflow-x: hidden;
                    direction: rtl;
                    position: relative;
                    @include mediaMaxWidth(800px){
                        padding: 0 1px; 
                    }
                }

                & header {
                    @include displayFlex(row, space-between, center);
                    height: 35px;
                    border-bottom: 1px solid $gray-border;
                    padding: 0 8px;
                    @include mediaMaxWidth(800px){
                        padding: 0 3px;
                        height: 21px;
                        @include displayFlex(row, center, center);
                    }
                }

                & header > span {
                    display: none;
                    @include mediaMaxWidth(800px){
                        display: block;
                        font-size: 12px;
                        font-weight: 100;
                    }
                }

                & header > span > span {
                    @include mediaMaxWidth(800px){
                        display: none;
                    }
                }

                & header > p:first-of-type {
                    font-size: 14px;
                    font-weight: bold;
                    @include mediaMaxWidth(800px){
                        display: none;
                    }
                }

                & header > p:last-of-type {
                    font-size: 14px;
                    font-weight: 500;
                    // color: $faded-black;
                    @include mediaMaxWidth(800px){
                        display: none;
                    }
                }


            }

            &::-webkit-scrollbar {
                height: 11px;
                width: 10px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgb(177, 177, 177);
                border-radius: 10px;
                margin-right: 189px;
                
            }
            &::-webkit-scrollbar-thumb {
                background: #0091ff;
                border-radius: 10px;
               
            }
        }
    }
}