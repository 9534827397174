.dataInfoMainContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    height: 50px;

    @media screen and (max-width: 601px) {
        padding: 16px !important;
    }
}

.mainContainer__input {
    display: flex;
    align-items: center;

}

.mainContainer__input--button {
    margin-top: 5px !important;
    align-items: end;
    justify-content: left;


    @media screen and (max-width: 950px) {
        margin-top: 3px !important;
    }
}

.mainContainer__iconContainer-xxxl {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0091FF;
    border: 1px solid;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 42px;
        height: 42px;
    }
}

.mainContainer__iconContainer-xxl {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0091FF;
}

.mainContainer__icon {
    color: #fff;
    font-size: 50px !important;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 28px !important;
    }
}

.mainContainer__icon--desktop {
    color: #fff;
    font-size: 28px !important;
}

.mainContainer__icon--mobile {
    color: #fff;
    font-size: 20px !important;
}

.mainContainer__locationBtn {
    margin-left: 0;
    display: flex !important;
    justify-content: center;
    width: 4.5% !important;
}

// mobile screen

@media screen and (max-width: 450px) {
    .tabletView {
        display: none !important;
    }

    .mainContainer__input--mobile-1 {
        margin-top: -27px !important;
    }

    .mainContainer__input--mobile {
        margin-top: 10px;
    }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .tabletView {
        display: none;
    }
}



@media screen and (min-width: 601px) {
    .mainContainer__locationBtn--tablet {
        display: block !important;
        position: relative;
    }
}

@media screen and (max-width: 600px) {
    .mainContainer__locationBtn--tablet {
        display: none !important;
    }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .mainContainer__locationBtn--tablet {
        padding-top: 0px;
        margin-top: 30px;
    }
}

.mainContainer__iconContainer-xxl--alignment {
    @media screen and (min-width: 601px) {
        position: relative;
        top: -8px;
        left: 2px;
    }
}


@media screen and (min-width: 1000px) {
    .mainContainer__input--multiSelect {
        padding-left: 10px;
    }
}

//
.reportOfCompletion__btn--alignment{
    position: relative;
    left: -15px;
}
.submitForCompletion__btn--alignment {
    position: relative;
    left: -10px;
}