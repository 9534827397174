
.amount__input{
//   margin-left: 19px;
  margin-right: 10px;
 
.MuiInputLabel-formControl {
    left: 73px !important;
  }

  .MuiInput-root {
      margin-top:4px;
    height: 24px !important;
}
}

