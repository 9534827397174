.tabEmptyLanding__main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
}

.tabEmptyLanding__main span {
    position: relative;
    top: calc(100vh - 65%);
    color: #455768;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}