.table__emptyContainer{
position: relative;
width: 61%;
height: 500px;

}
.table__noItem {
    position: absolute;
    top: 40%;
    left: 15%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 18px;
    color: #6C7389;
}



.checkListTableData table tbody {
  display: block;
  max-height: 467px;
  overflow-y: scroll;
  height: calc(100vh - 330px);
}

.checkListTableData table thead, .checkListTableData table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.checkListTableData table thead tr th, .checkListTableData table tbody tr td{
  text-align: center;
}