.mainContainer {
    width: 100%;
    height: auto;
    background: #ffffff;
    padding-top: 10px;
}

.blackStrap {
    width: 100%;
    height: 30px;
    background: #243748;
}

.mobileView {
    //  width: 100%;
    //  height: 100%;
}

@media screen and (max-width: 600px) {
    .mobileView {
        display: block;
    }

    .tabletView {
        display: none;
    }

    .desktopView {
        display: none;
    }
}

@media screen and (min-width: 601px) and (max-width: 1000px) {
    .mobileView {
        display: none;
    }

    .tabletView {
        display: none;
    }

    .desktopView {
        display: none;
    }
}

@media screen and (min-width: 1001px) {
    .mobileView {
        display: none;
    }

    .tabletView {
        display: none;
    }

    .desktopView {
        display: block;
    }
}

.display__none{
    display: none;
}
.display__block{
    display: block;
}

@media screen and (min-width: 1501px) {
    .mobileView {
        display: none;
    }

    .tabletView {
        display: block;
    }

    .desktopView {
        display: none;
    }
}