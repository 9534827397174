html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
 margin: 0;
 padding: 0;
 border: 0;
 font-size: 100%;
 font: inherit;
 vertical-align: baseline;
 overscroll-behavior-y: contain;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
 display: block;
}


body {
//  zoom: 80%;
 line-height: 1;
 direction: rtl;
 overflow: hidden !important;
//  overflow-x: hidden;
 font-family: 'Rubik';
 height: 100vh; /* Fallback for browsers that do not support Custom Properties */
//  overscroll-behavior-y: contain;
}
ol, ul {
 list-style: none;
}
blockquote, q {
 quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
 content: '';
 content: none;
}
button {
    cursor: pointer;
}
button:focus {
    outline: none;
}
input:focus {
    outline: none;
}
table {
 border-collapse: collapse;
 border-spacing: 0;
}
*{
    box-sizing: border-box;
    
}

// loader

.loader-new {
    height: 100%;
    width:100%;
    background-color: rgba($color: white, $alpha: 0.5);
    position: absolute;
    top:0;
    left:0;
    z-index: 200;
}


.logout__loader{
    .loader-new {
        height: 100vh;
       
    }
}

.custom-html-tooltip {
    background-color: 'white';
    color: '#455768';
    padding: '0px';
    margin: '0px !important';
    width: '222px';
    font-family: 'Rubik';
    border-radius: '6px';
    border: '2px solid #BEBEBE';
}