.mobileHeader__main {
    background-color: #fff;
    border: 0px 0px 12px 12px;
    box-shadow: 0px 0px 0px 0px #B3C7D60D, 0px 3px 7px 0px #B3C7D60D, 0px 12px 12px 0px #B3C7D60A, 0px 27px 16px 0px #B3C7D608, 0px 48px 19px 0px #B3C7D603, 0px 75px 21px 0px #B3C7D600;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 1rem 1rem 1rem;

    // width: 100%;
    max-width: 600px;
    height: 3.2rem;
    direction: ltr;
    position: sticky;
    margin-bottom: 0px;
    border-radius: 0px 0px 12px 12px;
    font-family: Rubik !important;

    @media screen and (min-width: 601px) {
        max-width: 100%;
        border-radius: 0px 0px 30px 30px;
        padding: 0.5rem 1rem;
    }

}

.mobileHeader__content{
    @media screen and (min-width: 601px) and (max-width: 800px) {
        display: flex;
        justify-content: right;
    }
}
.mobileHeader__row {
    @media screen and (min-width: 601px) {
        display: none !important;
    }
}

.mobileHeader__closePopUp {
    position: absolute;
    left: 2rem;
    top: 0.7rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.mobileHeader__clearIcon-container {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #0091FF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mobileHeader__clearIcon {
    font-size: 1.8rem !important;
    color: #fff;
    cursor: pointer;
}

.mobileHeader__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0px;
    width: 100%;
    height: 1.5rem;
    // border: 2px solid red;

    @media screen and (min-width: 601px) {
        // justify-content: center;
        justify-content: right;
    }

    @media screen and (min-width: 601px) and (max-width: 800px) {
        width: 90%;
    }
}

.mobileHeader__head__info {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    margin-right: 1.5rem !important;

    @media screen and (max-width: 600px) {
        margin-right: 0px !important;
    }
}

.mobileHeader__iconContainer__lg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #0091FF;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileHeader__iconContainer__lg-2 {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #EBF6FF;
    color: #0091FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mobileHeader__name {
    font-size: 1.5rem;
    line-height: 18px;
    font-weight: 800;
    color: #006DC0;
}

.mobileHeader__head__info__data {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rubik !important;

    @media screen and (min-width: 601px) {
        max-width: 331px;
        display: flex !important;
    }

    @media screen and (min-width: 601px) and (max-width: 800px) {
        margin-left: 20px !important;
    }
}

.mobileHeader__details {
    color: #0091FF;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    text-decoration: underline;

    // @media screen and (min-width: 451px) {
    //     font-size: 35px;
    // }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 24px;
    }
}

.mobileHeader__details--desktop {
    color: #0091FF;
    font-size: 1rem;
    line-height: 1rem; // 18px;
    font-weight: 600;
    text-decoration: underline;
}

.tabletHeader__details {
    color: #006DC0;
    font-size: 1.2rem; // 42px
    line-height: 1rem;
    font-weight: 800;
    font-family: Rubik !important;
    // width: 220px !important;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 1rem;
    }
}

.mobileHeader__details__link{
    cursor: pointer;
}

.tabletHeader__details--desktop {
    color: #006DC0;
    font-size: 1rem;
    line-height: 18px;
    font-weight: 800;
    font-family: Rubik !important;
}

.tabletHeader__details-wrap {
    width: 100%;
}

.mobileHeader__iconContainer__xxl {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: #EBF6FF;
    color: #0091FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mobileHeader__iconContainer__xl {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #EBF6FF;
    color: #0091FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mobileHeader__iconContainer__md {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #0091FF;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileHeader__iconContainer__sm {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #6C7389;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 451px) {
        width: 42px;
        height: 42px;
    }
}

.mobileHeader__icon-md {
    width: 24px;
    height: 24px;

    @media screen and (max-width: 450px) {
        width: 20px;
        height: 20px;
    }
}

.mobileHeader__icon-lg {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.mobileHeader__icon-xl {
    width: 47px;
    height: 47px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 22px;
        height: 22px;
    }

    @media screen and (max-width: 601px) {
        width: 20px;
        height: 20px;
    }
}

.mobileHeader__icon-xxl {
    width: 62px;
    height: 62px;
    cursor: pointer;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 32px;
        height: 32px;
    }

    @media screen and (max-width: 601px) {
        width: 32px;
        height: 32px;
    }
}

.mobileHeader__icon {
    font-size: 16px !important;

    @media screen and (min-width: 601px) {
        font-size: 22px !important;
    }
}


// tabelet and mobile screen google map

@media screen and (min-width: 601px) {
    .maibileHeader__googleMap {
        display: none;
    }
}