@import '../abstracts/mixins';
@import '../abstracts/variables';

.back-navigation {
    background-color: rgb(0, 145, 255);
    width: fit-content;
    height: 20px;
    border-radius: 16px;
    text-transform: uppercase;
    position: absolute;
    border: none;
    color: white;
    margin-right: 1%;
    padding-left: 1%;
    padding-right: 1%;
}