.system-tab-main {
    width: 100%;
    height: 65vh;
    display: flex;
    flex-direction: row;
    padding-top: 0.2rem;
}
.system-tab-main__right{
    margin-left: 10px !important;
    height: 100%;
    // overflow: hidden;   
    // overflow-y: scroll;
}
.system-tab-main__left {
    margin-right: 10px !important;

    .Empty-container{
        height: calc(100vh - 270px);
        overflow: auto;
    }
}
.system-tab-main__title {
    color: #006DC0;
    font-size: 1rem !important;
    line-height: 18px;
    font-weight: 800;
    margin-bottom: 0rem;
}

