@import "../abstracts/mixins";
@import "../abstracts/variables";

.daily-view-page {
  height: calc(100vh - 100px);
  @include displayFlex(row);
  border-top: 1px solid $faded-bluish-border;

  &__quantity {
    font-size: 15px;
    margin-top: auto;
  }

  &__warning {
    height: 10px;
    width: 10px;
    position: absolute;
    border-radius: 50%;
    bottom: 49px;
    left: 0;
    margin-bottom: 58px;
    margin-left: 7px;
  }
}

.daily-view-page__timepicker {
  width: 230px;
  height: 100%;
  background-color: #ffffff;

  position: relative;
  margin-left: 10px;
  padding-top: 10px;

  @include mediaMaxWidth(800px) {
    display: none;
  }

  & .react-datepicker {
    box-shadow: none !important;
  }
}

//
// new update process pop-up link
.popUp__background {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: rgba(74, 74, 74, 0.7);
}

.daily-view-page__update-process-popup {
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99999;
}

// new update process pop-up link

.daily-view-page__calender {
  height: 100%;
  flex-grow: 1;
  @include displayFlex(column);

  &__header {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    background-color: $blackish-text;
    @include displayFlex(row-reverse, space-between, center);

    &__right {
      @include displayFlex(row-reverse);

      @include mediaMaxWidth(800px) {
        width: 100%;
        @include displayFlex(row, space-between, center);
      }
    }

    &__right--dates {
      @include displayFlex(row, unset, center);

      @include mediaMaxWidth(800px) {
        width: 100%;
        @include displayFlex(row, space-between, center);
      }
    }

    &__right--dates_dark {
      @include displayFlex(row, unset, center);

      @include mediaMaxWidth(800px) {
        width: 100%;
        @include displayFlex(row, space-between, center);
      }
    }

    &__right--clock {
      @include displayFlex(row, center, center);
      height: 26px;
      border-radius: 4px;
      background-color: $neon-green-strong;
      color: white;
      font-size: 14px;
      margin-left: 20px;
      padding: 0 6px;

      @include mediaMaxWidth(800px) {
        display: none;
      }

      &>img {
        margin-right: 5px;
        transform: translateY(-1px);
      }
    }

    &__right--dates>figure {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
      @include displayFlex(row, center, center);
    }

    &__right--dates_dark>figure {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: #d6d2d2;
      cursor: pointer;
      @include displayFlex(row, center, center);
    }

    &__right--dates>figure:nth-of-type(2) {
      transform: rotate(180deg);
    }

    &__right--dates_dark>figure:nth-of-type(2) {
      transform: rotate(180deg);
    }

    &__right--dates>p {
      font-size: 14px;
      color: #ffffff;
      margin: 0 10px;
    }

    &__right--dates_dark>p {
      font-size: 14px;
      color: rgb(0, 0, 0);
      margin: 0 10px;
    }

    &__left {
      @include backToTodayBtn();
      position: absolute;

      @include mediaMaxWidth(800px) {
        top: -96px;
        right: 112px;
        border-radius: 0;
        height: 56px;
        width: 56px;
      }

      @include mediaMinWidth(800px) {
        background-color: #0091FF;
        width: fit-content;
        height: 20px;
        border-radius: 16px;
        padding: 10px;
        text-transform: uppercase;
        position: absolute;
      }
    }

    &__left--drop {
      height: 26px;
      @include displayFlex(row, space-between, center);
      width: 200px;
      border-bottom: 1px solid white;
      font-size: 14px;
      color: #ffffff;
      padding: 0 10px;
      cursor: pointer;
      position: relative;

      &--active>ul {
        transform: scaleY(1) !important;
        transition: transform 0.2s;
        transform-origin: top;
      }
    }

    &__left--drop>img {
      transform: rotate(90deg);
    }

    &__left--drop>ul {
      @include appDropdown;
      color: black;

      &>li {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        @include displayFlex(row, flex-start, center);
        padding-right: 12px;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }

  //end header

  &__main {
    height: 100%;
    overflow-y: scroll;
    background-color: $gray-background;
    padding: 12px;

    &--no-process {
      @include displayFlex(row, center, center);
      height: 100%;

      &>h1 {
        font-size: 18px;
      }
    }

    &__process {
      width: 100%;
      height: 128px;
      border-radius: 3px;
      padding: 7px 12px;
      border: solid 1px #cfcfcf;
      background-color: #ffffff;
      margin-bottom: 10px;
      @include displayFlex(row, space-between, center);
      position: relative;

      &>.done__button {
        width: 98px;
        height: 80px;
        border-radius: 4px;
        background-color: #0091ff;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
      }

      &>.done__icon {
        margin-left: 33px;
      }
    }

    &__process>aside {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 5px;
    }

    &__process>aside>span {
      height: 5px;
      display: block;
    }

    &__process>main {
      height: 100%;
      @include displayFlex(column, unset, flex-start);
      padding-top: 7px;
    }

    &__content {
      &--first>p {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 4px;
        display: inline;
      }

      &--first>h6 {
        font-size: 18px;
        font-weight: 500;
        display: inline;
      }

      &--second {
        @include displayFlex(row, flex-end, center);
        margin-top: 5px;
        font-size: 15px;

        @include mediaMaxWidth(800px) {
          flex-direction: column;
          align-items: unset;

        }
      }

      &--second>p {
        font-size: 14px;
      }
    }

    &::-webkit-scrollbar {
      height: 11px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgb(177, 177, 177);
      border-radius: 10px;
      margin-right: 189px;

    }

    &::-webkit-scrollbar-thumb {
      background: #0091ff;
      border-radius: 10px;
    }
  }
}

.daily__employees__name__hyphen {
  @include mediaMaxWidth(800px) {
    display: none;
  }
}

.row-second-value {
  margin-right: 15px;
}

.four_characters_length {
  max-width: 45px;
  margin-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}