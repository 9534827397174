.precent__input {
  margin-right: 10px;

  .MuiTypography-body1 {
    font-size: 15px;

    padding-left: 2px;
    font-weight: bold;
  }

  .MuiInput-root {
    height: 24px !important;
    width: 73px!important;
    font-size: 14px!important;
    margin-top:4px;
  }

  .MuiInputBase-input {
    text-indent: 7px;
  }
}
