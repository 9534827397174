.checkBtn__main {
    width: 300px;
    height: 52px;
    border-radius: 6px;
    padding: 14px 16px;
   // border: 1px solid #CACBCC;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
   // box-shadow: 0px 0px 0px 0px #0844721A, 0px 1px 2px 0px #0844721A, 0px 3px 3px 0px #08447217, 0px 6px 4px 0px #0844720D, 0px 11px 5px 0px #08447203, 0px 18px 5px 0px #08447200;
}

// .checkBtn__main--tablet {
//     width: 100%;
//     height: 52px;
//     border: none;
//     border-radius: 6px;
//     padding: 0px 16px;
//     background-color: transparent;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     box-shadow: 0 0 0 0 transparent;
//     border: 1px solid #CACBCC;
//     background-color: #fff;
// }

.checkBtn__main input {
    width: 18px;
    height: 18px;
    border-color: #455768;
    // outline: 2px solid #1e5180
}

// .checkBtn__main__input--tablet{
//     border-color: #186315 !important;
// }

.checkBtn__label {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #455768;
}

