.uploadFileBtn__main {
    width: 100%;
    height: 42px;
    border: 2px solid #0091FF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 30px;

    @media screen and (max-width: 450px) {
        margin: 0px;
    }
}

.uploadFileBtn__btn-title {
    font-size: 12px;
    line-height: 15px;
    font-weight: 800;
    color: #0091FF;
    font-family: Rubik !important;
}

.uploadFileBtn__btn {
    // padding: 10px 16px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
}

.uploadFileBtn__icon__container {
    width: 24px;
    height: 24px;
    background-color: transparent;
    color: #0091FF;
}

.uploadFileBtn__icon {
    width: 22px;
    height: 22px;
    // font-size: 12px;
    // font-weight: 800;
    // line-height: 15px;
}


// tablet view style
.uploadFileBtn__main--tablet {
    width: 100%;
    // height: 70px;
    // border: 2px solid #0091FF;
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // margin-top: 30px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 52px;
        border-radius: 20px;
        margin-bottom: 0px;
    }
}

.uploadFileBtn__btn--tablet {
    // padding: 21px 16px;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
}

.uploadFileBtn__btn-title--tablet {
    font-size: 24px;
    line-height: 29px;
    font-weight: 800;
    color: #0091FF;
    font-family: Rubik !important;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
        line-height: 15px;
    }
}

.uploadFileBtn__icon__container--tablet {
    width: 32px;
    height: 32px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 24px;
        height: 24px;
    }
}

.uploadFileBtn__icon--tablet {
    // font-size: 32px;
    // font-weight: 800;
    // line-height: 15px;
    width: 29px;
    height: 29px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 22px;
        height: 22px;
    }
}


// desktop style
.uploadFileBtn__main--desktop {
    width: 35% !important;
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    margin-top: 0px;
    text-decoration: underline;

    @media screen and (max-width: 450px) {
        margin: 0px;
    }
}