.checkListPopup__container {
    max-width: 1090px;
    width: 966px;
    height: 100vh;
    background-color: #F9F9F9;
    padding: 32px;
    border-radius: 20px;
    position: absolute;
    // left: calc((100vw/2)- 545px);
    left: 0px;
    gap: 24px;
    direction: rtl;
}

.checkListPopup__title {
    font-size: 24px;
    line-height: 18px;
    font-weight: 800;
    text-align: right;
    color: #006DC0;
}

.checkListPopup__systemContainer {
    margin-top: 20px;
    width: 100%;
    height: 78px;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #EBF6FF;
    box-shadow: 0px 0px 0px 0px #8787870D, 0px 1px 2px 0px #8787870D, 0px 4px 4px 0px #8787870A, 0px 9px 5px 0px #87878708, 0px 16px 6px 0px #87878703, 0px 25px 7px 0px #87878700;
    border-radius: 6px;
}

.checkListPopup__col {
    display: flex;
    flex-direction: column;
}

.checkListPopup__systemTitle {
    font-size: 16px;
    line-height: 18px;
    font-weight: 800;
    color: #006DC0;
}

.checkListPopup__details {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #455768;
}

.checkListPopup__table {
    width: 100%;
    height: calc(100vh - 146px);
    margin-top: 20px;
    overflow: auto;
}