.addStepPopUp__main {
    max-width: 580px;
    background-color: #F9F9F9;
    z-index: 9999;
    position: absolute;
    top: 0px;
    left: calc((100vw/2) - 580px);
    border-radius: 20px;
    padding: 32px;
    direction: rtl;
    opacity: 1.0;
}

.addStepPopUp__name {
    font-size: 24px;
    font-weight: 800;
    line-height: 18px;
    color: #006DC0;
    background-color: transparent;
    margin-bottom: 10px;
}

.addStepPopUp__inputRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.addStepPopUp__inputRow__input__left {
    margin-left: 10px;
}

.addStepPopUp__inputRow__input__right {
    margin-right: 10px;
}

.addStepPopUp__btn__container{
    display: flex;
    justify-content: right;
    flex-direction: row;
    margin-top: 32px;
}