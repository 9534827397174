.btnContainer__main {
    width: 100%;
    height: 52px;
    //position: relative;
    //  bottom: 0px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: #fff;
}

.button {
    display: flex;
    background-color: #186315;
    border-radius: 20px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    @media screen and (max-width: 450px) {
        padding: 0;
    }
}

.button__iconContainer {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    color: #186315;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 450px) {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

}

.button__imgIcon {
    width: 32px;
    height: 32px;
    // margin-left: 10px;
    position: absolute;
    left: 54%;

    @media screen and (max-width: 600px) {
        width: 18px;
        height: 18px;
    }

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        width: 18px;
        height: 18px;
    }
}

.button__imgIcon--desktop {
    width: 22px;
    height: 22px;
    // margin-left: 10px;
    position: absolute;
    left: 54%;

    @media screen and (max-width: 450px) {
        width: 18px;
        height: 18px;
    }
}

.button__text {
    font-size: 16px;
    line-height: 18px;
    font-weight: 800;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width: 450px) {
        font-size: 12px !important;
    }
}
.button__icon-xl{
    font-size: 30px;

    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 20px;
    }
}
.button__icon-fff{
    color: #fff;
}