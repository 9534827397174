.tabletViewMain {
  // background: #EEEEEE; //#BBBBBB;
  width: 100%;
  height: 100vh;
  z-index: 3 !important;
  background-color: #F9F9F9;
  // overflow-y: scroll;
}

.dataContainer {
  margin: 0px 1rem;


  @media screen and (max-width: 1050px) {
    margin: 0px 1rem;
  }
}

.navigation__container{
  margin-top: 10px;

  @media screen and (max-width: 1050px) {
      height: 8vh;
  }
}

// desktop
.tabletViewMain--desktop {
  max-width: 966px;
  position: relative;
  // left: calc((100vw/2) - 480px);

        // max-width: fit-content;
        margin-inline: auto;
        height: 100vh;
        background-color: #F9F9F9;
        z-index: 3 !important;

}

// add order should algin more on left side
.addOderPopup__position{
  position: absolute;
  left: 0;
  // border: 2px solid red;
  width: 100%;
  // height: auto;
}

