@import "../abstracts/mixins";
@import "../abstracts/variables";

.drop__down__box__container {
  margin-bottom: 0px !important;

  .drop__down__box {
    height: 44px;
    cursor: pointer;
    width: 100%;
   
    cursor: pointer;
  }
}

.drop__down__box__icon {
  margin-left: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
//   margin-top: 4px;
  .fa-sort-down {
    font-size: 17px;
    
  }
  .fa-sort-up {
    position: relative;
    top: 4px !important;
  }
}

#drop__down__box--off {
  transform: scaleY(0) !important;
        transition: transform 0.2s;
        transform-origin: top;
  // li {
  //   display: none;
  // }
}

.drop__down__header__select {
  font-family: Rubik;
  font-size: 16px;
  // padding-top: 12px;
  display: flex;
  width: 100%;
  height: 40px;
  padding-right: 10px;
  border-radius: 5px;
  border: solid 1px #e2e2e2;
  background-color: #f9f9f9;
  cursor: pointer;
}

.drop__down__box__arrow__icon{
  position: relative;
  bottom: 2px;
}

#drop__down__box--active {
  padding: 11px 3px;
  transform: scaleY(1);
  transition: transform 0.2s;
  transform-origin: top;
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  
     


  li {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 5px;
    @include displayFlex(row, flex-start, center);
    padding: 4px;
    background-color: white;
    align-items: center;
    &:hover {
      background-color: #f1f1f1;
    }
  }
}


.drop__down__list {
  overflow-y: auto;
  z-index: 10;
  position: relative;
  background-color: white;
  width: 200px;
  border-radius: 5px;
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);

  .drop__down__option {
    // height: 44px;
    background-color: #f9f9f9;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Rubik;
   
    font-size: 16px;
    width: 200px;

    span {
      margin-right: 5px;
    }
  }
}

/* width */
.drop__down__list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.drop__down__list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.drop__down__list::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.drop__down__list::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.drop__down__option:hover {
  background-color: #dbdbdb;
}
