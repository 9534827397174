.FileTab__main {
    // display: flex;
    // flex-direction: column;
    background-color: #F9F9F9; // #F2F7FB;
    width: 100%;
    height: 100vh;
    justify-content: center;

}
.FileTab__filesList {
    width: 100%;
    height: 100vh;
    margin: 0 16px;
    overflow-y: scroll;
}
.FileTab__filesList::-webkit-scrollbar-track {
    background-color: transparent;
    border: none;
    margin: 0px;
}

.FileTab__filesList::-webkit-scrollbar {
    width: 5px;
    height: 50px;
}

.FileTab__filesList::-webkit-scrollbar-thumb {
    margin: 15px;
    border-radius: 4px;
    background-color: #006DC0;
}