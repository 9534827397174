@import '../abstracts/mixins.scss';
@import '../abstracts//variables';

.general__review__container{
    @include displayFlex(row, unset, unset);
}


.general-review {
    height: calc(100vh - 100px);
    background-color: $gray-background;
    width: 100%;
    // padding: 0 30px;
    position: relative;
    @include displayFlex(row, unset, unset);
    padding-top: 25px;

.general__reports__container{
    @include displayFlex(row, unset, unset);
    margin: auto;

}

     .section {
        width: 500px;
        height: calc(100vh - 164px);
        background-color: white;

        &  header {
            @include displayFlex(row, space-between, center);
            padding: 16px;
            border-bottom: 1px solid  #d4dade;
            height: 67px;
        }

        &  header > h1 {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.32px;
            color: #001f40;
        }

        &  header > button {
            width: 84px;
            height: 34px;
            border-radius: 100px;
            background-color: rgba(36, 55, 72, 0.1);
            font-size: 16px;
            color: rgba(0, 0, 0, 0.6);
            border:none;
        }
    }

     .section:first-of-type {
        margin-left:30px;
    }
}

.empty__review__dashboard__message{
    text-align: center;
    font-size: 18px;
    margin-top: 25px;
}


